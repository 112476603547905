import axios from 'axios';
import { AUTH_NO_TOKEN } from './types';

// Unsafe HTTP operations are those that (can) have side effects: POST, DELETE, PATCH
// are the three main items of concern here. All unsafe operation requests to the API
// require a CSRF token to be included in the HTTP headers to prevent replay and
// session riding attacks. But, we don't just want to randomly include it with every
// request.
const isUnsafeOperation = (method) => {
	return (method !== 'get' && method !== 'head');
};

const fzAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE,
    withCredentials: true,
    headers: {
        'Content-type': 'application/json'
    }
});

const fzAxiosInterceptors = (store) => {
	// When we make an unsafe request, include the CSRF token in the request headers
	// to help prevent CSRF attacks. Also, set the content-type to JSON for all request
	// types.
	fzAxiosInstance.interceptors.request.use((cfg) => {
		if(isUnsafeOperation(cfg.method)) {
			const token = store.getState().auth.csrftoken;
			if(!token) {
				store.dispatch({ type: AUTH_NO_TOKEN });
			}
			cfg.headers['X-CSRFToken'] = token;
		}
		cfg.headers['Content-Type'] = 'application/json';

		return cfg;
	});


	// Technically any unsafe operation should result in a token rotation to improve
	// protection against replay attacks. However, Django continues to accept old
	// tokens when a rotation is performed We need to do some reading and see why it
	// does, what can be done to correct it, and if it's worth doing so.
	// Since we use HTTPS everywhere and the cookie is both SECURE and HTTPONLY, the
	// chances of someone obtaining both a valid cookie AND a valid CSRF token from
	// an intercepted request are very slim.
	/*fzAxiosInstance.interceptors.response.use((resp) => {
		if(resp.data['X-CSRFToken']) {
			store.dispatch({
				type: CSRF_UPDATE,
				payload: { csrftoken: resp.data['X-CSRFToken'] }
			});
		}
		return resp;
	});*/
};

export default fzAxiosInstance;
export { fzAxiosInterceptors };