/* Modals are focus-stealing dialog boxes used to deliver important or asked-for
 * information to the user. Every modal requires some state from its parent to be
 * passed into it: a variable to determine if it should be shown, and a function
 * that can be used to set this variable to false when it's time to hide it. Modals
 * can alternatively have a title, and the ActionableModal can have custom
 * components passed to its actions prop, intended to be something like a button that
 * the user can interact with. props.lockout will disable any default interface items
 * in the modal, for example disabling a "cancel" button while an action processes. */
import {
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    Layer,
    ResponsiveContext,
} from 'grommet';
import { useCallback, useContext } from 'react';

// A simple modal that only displays information. It has an "okay" button
export const InformationalModal = ({body, show, setShow, ...props}) => {
    const size = useContext(ResponsiveContext);

    const handleModalHide = useCallback(e => {
        setShow(false);
    }, [setShow]);

    return (
    <>
    {show && (
        <Layer
            position='center'
            responsive={false}
            full={size === 'small' ? 'horizontal' : false}
            margin={size === 'small' ? 'small' : 'none'}
            animation='slide'
            onEsc={handleModalHide}
            onClickOutside={handleModalHide}>
    
            <StandardFZModal
                body={body}
                foot={
                    <CardFooter
                        direction='row-responsive'
                        justify='center'
                        border={{color: 'border', side: 'top'}}
                        pad='small'>
                
                        <Button
                            primary
                            color='brand'
                            label='Okay' 
                            disabled={props.lockout || false}
                            onClick={handleModalHide } />
                    </CardFooter>
                }
                {...props} />
        </Layer>
    )}
    </>
    );
};

// A modal related to user actions (delete confirmation, etc.) that does more than
// just display information and probably has one or more actions with side effects.
export const ActionableModal = ({body, show, setShow, actions, ...props}) => {
    const size = useContext(ResponsiveContext);

    const handleModalHide = useCallback(e => {
        setShow(false);
    }, [setShow]);

    return (
    <>
    {show && (
        <Layer
            position='center'
            responsive={false}
            full={size === 'small' ? 'horizontal' : false}
            margin={size === 'small' ? 'small' : 'none'}
            animation='slide'
            onEsc={handleModalHide}
            onClickOutside={handleModalHide}
            style={{ border: {round: 'small'} }}>
    
            <StandardFZModal
                body={body}
                foot={
                    <CardFooter
                        direction='row-responsive'
                        justify={props.footJustify || 'end'}
                        border={{color: 'border', side: 'top'}}
                        pad='small'>
                    
                        <Button
                            secondary
                            color={props.noOpColor || 'brand'}
                            label={props.noOpLabel || 'Cancel'}
                            disabled={props.lockout || false}
                            onClick={handleModalHide } />

                        {actions}
                    </CardFooter>
                }
                {...props} />
        </Layer>
    )}
    </>
    );
};

const StandardFZModal = ({body, foot, ...props}) => {
    return (
    <Card
        flex
        fill='horizontal'
        width={{max: 'large'}}
        direction='column'
        round='xxsmall'
        background='background-back'
        border={{color: 'brand', size: 'small'}}
        elevation='xlarge'>
        
        <CardBody
            direction='column'
            gap='small'
            overflow='scroll'>        
            {props.head && (
                <Box
                    background='brand'
                    align='center'
                    justify='center'
                    pad='xsmall'>
    
                    {props.head}
                </Box>
            )}
                
            <Box
                direction='row'
                align='center'
                gap='medium'
                pad='small'
                overflow='scroll'>
    
                {body}
            </Box>

        {foot}
        </CardBody>
    </Card>
    );
};
