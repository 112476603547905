import {
    Box,
    Heading,
    Image,
    Layer
} from 'grommet';

const FullScreenLoadingSpinner = ({msg=''}) => (
    <Layer
        modal={true}
        full>
        
        <Box
            fill
            background='background'
            align='center'
            justify='center'>
            {msg ? <Heading size='small'>{msg}</Heading> : null}
        
            <Box
                width='medium'
                height='medium'
                align='center'
                justify='center'
                animation='rotateRight'> 
            
                <Image
                    size='medium'
                    a11yTitle='Loading Spinner'
                    src='/assets/fz_logo.png' />
            </Box>
        </Box>
    </Layer>
);

export { FullScreenLoadingSpinner };
