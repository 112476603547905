import {
    Box,
    Button,
    Heading,
    Text,
} from 'grommet';

export const ErrorField = ({msg, show, setShow}) => {
    return (
    show && (
    <Box
        direction='row-responsive'
        animation='fadeIn'
        round='xsmall'
        border={{'color': 'status-critical', 'size': 'small'}}
        justify='between'
        gap='small'
        fill='horizontal'
        background='status-critical-bg'
        color='status-critical-text'>
        <Box align='center' justify='center' pad={{left: 'medium'}}>
            <Heading size='medium' color='status-critical-text'>
                <i className='fas fa-bomb' />
            </Heading>
        </Box>
        <Box
            fill='horizontal'
            align='left'
            margin={{left: 'small'}}
            justify='center'
            weight='bold'>
            <Text color='status-critical-text' weight='bold'>{msg}</Text>
        </Box>
        <Box
            align='right'
            justify='top'
            pad={{right: 'xsmall'}}>
            <Button plain color='status-critical-text' onClick={() => setShow(false)}>
                <i className='far fa-times-circle' />
            </Button>
        </Box>
    </Box>
    ));
};
