import _ from 'lodash';
import {
    Box,
    Button,
    DateInput,
    Form,
    FormField,
    Text,
    TextArea,
    TextInput,
    Tip
} from 'grommet';
import { StandardCard } from '../common/cmp_cards';
import {
    SuccessNotification,
    WarningNotification 
} from '../common/cmp_notifications';
import { updateSelfProfile } from './actions';
import { buildDateString, buildLocationString } from '../common/helpers';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const SelfProfile = (props) => {
    const user = useSelector((state) => state.user.user);
    const [isEditing, setIsEditing] = useState(false);

    return (
    <Box
        direction='column'
        align='center'
        gap='medium'
        fill='horizontal'>
        
        <Text weight='bold'>{user.profile.bio || 'No bio provided'}</Text>
       
        <SelfProfileCard name='Your' user={user} />
    </Box>
    );
};

const SelfProfileCard = ({name, user}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [showSuccessNotification, setShowSuccessNotification] = useState(false);

    const toggleIsEditing = () => {
        setIsEditing(!isEditing);
    };

    const activateSuccessNotification = () => {
        setShowSuccessNotification(true);
    };

    return (
    <>
    {isEditing ? (
        <ProfileEditCard
            profile={user.profile}
            setIsEditing={toggleIsEditing}
            successNotificationFn={activateSuccessNotification} />
        ) : (
        <ProfileViewCard
            name={name}
            user={user} 
            setIsEditing={toggleIsEditing} />
    )}
    <SuccessNotification
        show={showSuccessNotification}
        setShow={setShowSuccessNotification}
        timeout={5000}
        msg='Profile updated successfully.' />
    </>
    );
};

const ProfileViewCard = ({name, user, setIsEditing}) => {
    return (
    <StandardCard
        head={`${name} Information`}
        body={
            <>
            <Box
                direction='column'
                fill='horizontal'
                justify='center'
                border={{side: 'bottom', color: 'brand'}}
                pad={{horizontal: 'small'}}>

                <Text 
                    a11yTitle='Your profile bio'
                    textAlign='center'
                    weight='bold'>

                    {user.profile.bio || 'No bio provided'}
                </Text>

                <AttributeBox
                    attr='Location'
                    value={buildLocationString(user.profile)} />

                <AttributeBox
                    attr='Birthday'
                    value={buildDateString(user.profile.birthday)} />
                <AttributeBox attr='Z-Code' value={user.profile.code} />
            </Box>
            <Box
                wrap
                direction='row'
                fill='horizontal'
                align='center'
                justify='center'>
                
                <BadgeBox label={user.profile.generation} background='text-strong' color='background-front' info={'Generation ' + user.profile.generation} />

                {user.profile.is_old_100
                    ? <BadgeBox label='100' background='status-ok' color='status-ok' info='Old 100' />
                : null
                }
                {user.profile.is_custodian
                    ? <BadgeBox label='C' background='status-warning' color='status-critical' info='Custodian' />
                    : null
                }
            </Box>
            </>
        } 
        foot={
            <Box
                fill='horizontal'
                justify='end'
                align='center'>
                        
            <Button
                secondary
                color='brand'
                label='Edit'
                icon={<i className='fas fa-user-edit' />}
                onClick={setIsEditing} />
        </Box>
    }/>
    );
};

const AttributeBox = ({attr, value}) => {
    return (
    <>
    <Text>{attr}:</Text>
    <Text weight='bold' margin={{left: 'small', bottom: 'small'}}>
        {value}
    </Text>
    </>
    );
};

const BadgeBox = ({label, color, background, info}) => (
    <Tip
        plain
        dropProps={{ align: { 'top': 'bottom' } }}
        content={
            <Box
                background='brand'
                round='medium'
                elevation='medium'
                animation='fadeIn'
                align='center'
                justify='center'
                margin={{left: 'medium', top: 'xsmall', bottom: 'medium', right: 'medium'}}
                pad={{ horizontal: 'small', vertical: 'xsmall'}}>
           
                {info}
            </Box>
        }>
        
    <Box
        round='xxsmall'
        width='xxsmall'
        height='xxsmall'
        margin='small'
        align='center'
        justify='center'
        color={color}
        background={{color: background, opacity: 'medium'}}
        border={{color: background, size: 'small'}}>
        
        <Text weight='bold'>{label}</Text>

    </Box>
    </Tip>
);

const ProfileEditCard = ({profile, setIsEditing, successNotificationFn}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formValues, setFormValues] = useState({
        city: profile.city || '',
        state: profile.state || '',
        country: profile.country || '',
        bio: profile.bio || '',
        birthday: profile.birthday || ''
    });
    const [formErrors, setFormErrors] = useState({});
    const [showEmptyNotification, setShowEmptyNotification] = useState(false);
    const dispatch = useDispatch();

    const setFormDefaults = () => {
        setFormValues({
            city: profile.city,
            state: profile.state,
            country: profile.country,
            bio: profile.bio,
            birthday: profile.birthday
        });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        const subValues = {};
        for(const p in formValues) {
            if(formValues[p] !== profile[p] &&
                    (formValues[p] !== '' || profile[p])) {
                if(p === 'birthday') {
                    subValues[p] = new Date(formValues[p].toISOString()).toJSON().slice(0, 10);
                }
                else {
                    subValues[p] = formValues[p];
                }
            }
        }
        if(_.isEmpty(subValues)) {
            setShowEmptyNotification(true);
        }
        else {
            dispatch(updateSelfProfile(subValues), [dispatch]).then((res) => {
                if(res.success) {
                    setIsSubmitting(false);
                    successNotificationFn();
                    setIsEditing(false);
                }
                else {
                    setFormErrors(res.errors);
                }
            });
        }
        setIsSubmitting(false);
    }

    const handleFormChange = useCallback(
        newValues => {
            setFormValues({
                ...newValues,
                bio: newValues.bio.slice(0, 256)
            });
        },
        [setFormValues]
    );

    return (
    <Form
        name='profile-edit-form'
        value={formValues}
        errors={formErrors}
        onReset={setFormDefaults}
        onChange={handleFormChange}
        onSubmit={handleFormSubmit}>
    
        <StandardCard
            head='Edit Profile'
            body={
                <Box
                    direction='column'
                    fill='horizontal'
                    align='center'
                    gap='small'
                    margin={{horizontal: 'medium'}}>

                    <FormField
                        a11yTitle='Edit bio text area'
                        name='bio'
                        label='Bio'
                        info={`A little bit about yourself (${formValues.bio.length}/256)`}
                        style={{width: '100%'}}
                        resize={false}
                        rows='3'
                        as={TextArea} />

                    <FormField
                        a11yTitle='Edit birthday'
                        name='birthday'
                        label='Birthday'
                        info='Birthday'
                        style={{width: '100%'}}>
                        
                        <DateInput
                            name='birthday'
                            format='mm/dd/yyyy'
                            inline={false}
                            icon={<i className='fas fa-calendar-alt' />} 
                            dropProps={{overflow: 'scroll'}}/> 
                    </FormField>

                    <Box
                        direction='row-responsive'
                        fill='horizontal'
                        gap='small'>
    
                        <FormField
                            a11yTitle='Edit city text field'
                            name='city'
                            label='City'
                            info='City or Municipality'
                            as={TextInput} />
    
                        <FormField
                            a11yTitle='Edit state/province text field'
                            name='state'
                            label='State'
                            info='State (or Equivalent)'
                            as={TextInput} />
    
                        <FormField
                            a11yTitle='Edit country field'
                            name='country'
                            label='Country'
                            info='Country'
                            as={TextInput} />
                            
                    </Box>
                </Box>
            }
            foot = {
                <Box
                    direction='row-responsive'
                    fill='horizontal'
                    gap='medium'
                    justify='end'>

                    <Button
                        secondary
                        color='status-warning'
                        label='Cancel'
                        icon={<i className='fas fa-times' />} 
                        disabled={isSubmitting}
                        onClick={setIsEditing} />
                    
                    <Button
                        primary
                        color='status-ok'
                        label='Update'
                        icon={isSubmitting 
                            ? <i className='fas fa-spin fa-spinner' />
                            : <i className='fas fa-user-edit' />}
                        disabled={isSubmitting}
                        type='submit'
                        onClick={handleFormSubmit} />
                </Box>
            } />
        <WarningNotification
            show={showEmptyNotification}
            setShow={setShowEmptyNotification}
            timeout={5000}
            msg='You have to actually make a change to update your profile.' />
    </Form>
    );
};

export default SelfProfile;
