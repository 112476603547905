import {
	APP_LOADING,
	APP_LOAD_FAIL,
	APP_LOAD_SUCCESS
} from '../common/types';

const initialState = {
	isLoading: true,
	isGoodState: true
};

export const app_reducer = (state=initialState, action) => {
	switch(action.type) {
		case APP_LOADING:
			return {
				...state,
				isLoading: true
			};
		case APP_LOAD_FAIL:
			return {
				...state,
				isLoading: false,
				isGoodState: false
			};
		case APP_LOAD_SUCCESS:
			return {
				...state,
				isLoading: false,
				isGoodState: true
			};
		default:
			return state;
	}
};