import {
    Box,
    Button,
    Layer,
    Text
} from 'grommet';
import { FormClose } from 'grommet-icons';
import { useEffect } from 'react';

// A happy, green success notification
export const SuccessNotification = ({ show, setShow, msg, ...props}) => {
    return (
    <FZNotification
        show={show}
        setShow={setShow}
        msg={msg}
        bg='status-ok'
        icon={props.icon ? props.icon : <i className='fas fa-checkcircle' />} 
        {...props} />
    );
};

// A noticeable, yellow warning notification for non-critical problems or successful completion
// of destructive actions (e.g. deleting a post, unfriending someone)
export const WarningNotification = ({show, setShow, msg, ...props}) => {
    return (
    <FZNotification
        show={show}
        setShow={setShow}
        msg={msg}
        bg='status-warning'
        icon={props.icon ? props.icon : <i className='fas fa-exclamation-circle' />}
        {...props} />
    );
};

// An angry, red error notification to warn the user when something goes wrong
export const ErrorNotification = ({show, setShow, msg, ...props}) => {
    return (
    <FZNotification
        show={show}
        setShow={setShow}
        msg={msg}
        bg='status-critical'
        icon={props.icon ? props.icon : <i className='fas fa-bomb' />}
        {...props} />
    );
};

// A chill, purple notification for providing bite-sized pieces of information to the user.
export const InfoNotification = ({show, setShow, msg, ...props}) => {
    return (
    <FZNotification
        show={show}
        setShow={setShow}
        msg={msg}
        bg='brand'
        icon={props.icon ? props.icon : <i className='fas fa-info-circle' />}
        {...props} />
    );
};

const FZNotification = ({show, setShow, msg, bg, icon, ...props}) => {
    const onClose = () => setShow(undefined);

    useEffect(() => {
        if(props.timeout) {
            const timer = setTimeout(() => {
                setShow(undefined);
            }, Number.isInteger(props.timeout) ? props.timeout : 3000);
            return () => clearTimeout(timer);
        }
    });

    return (
        <>
        {show && (
        <Layer
            position='bottom'
            modal={false}
            margin={{'vertical': 'medium', 'horizontal': 'small'}}
            responsive={false}
            //animation='slide'
            animate={true}
            onEsc={onClose}
            plain>
            <Box
                align='center'
                direction='row'
                gap='small'
                justify='between'
                round='medium'
                elevation='large'
                pad={{'vertical': 'xsmall', 'horizontal': 'small'}}
                background={bg}>

                <Box align='center' direction='row' gap='xsmall'>
                    {icon}
                    <Text>{msg}</Text>
                </Box>
                <Button icon={<FormClose />} onClick={onClose} plain />
            </Box>
        </Layer>
        )}
        </>
    );
};