import {
    USER_LOAD,
    USER_LOAD_SUCCESS,
    LOGIN_SUCCESS,
    LOGOUT_FAIL,
    LOGOUT_SUCCESS,
    SENDING_INVITATION,
    UPDATE_INVITE_DATA_FAIL,
    UPDATE_INVITE_DATA_SUCCESS,
} from '../common/types';

const initialState = {
    inconsistent: false,
    isSending: false,
    isLoading: false,
    manager: null,
    pending: null
};

export const invitation_reducer = (state = initialState, action) => {
    switch(action.type) {
        case USER_LOAD:
            return {
                ...state,
                isLoading: true
            };
        case LOGIN_SUCCESS:
        case USER_LOAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                manager: action.payload.invitations.manager,
                pending: action.payload.invitations.pending
            };
        case LOGOUT_FAIL:
        case LOGOUT_SUCCESS:
            return {
                ...state,
                manager: null,
                pending: null
            };
        case SENDING_INVITATION:
            return {
                ...state,
                isLoading: true
            };
        case UPDATE_INVITE_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isInconsistent: false,
                manager: action.payload.invitations.manager,
                pending: [action.payload.invitations.pending, ...state.pending]
            };
        case UPDATE_INVITE_DATA_FAIL:
            return {
                ...state,
                isLoading: false,
                isInconsistent: true
            };
        default:
            return state;
    }
};
