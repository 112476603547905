// FRIEND REQUEST ACTIONS
import axios from '../common/axios';
import { httpErrorHelper } from '../common/network/http';
import {
	FRIEND_REQUEST_ACCEPT,
	FRIEND_REQUEST_ACCEPT_FAIL,
	FRIEND_REQUEST_ACCEPT_SUCCESS,
	FRIEND_REQUEST_DECLINE,
	FRIEND_REQUEST_DECLINE_FAIL,
	FRIEND_REQUEST_DECLINE_SUCCESS,
	FRIEND_REQUEST_REVOKE,
	FRIEND_REQUEST_REVOKE_FAIL,
	FRIEND_REQUEST_REVOKE_SUCCESS,
	FRIEND_REQUEST_SEND,
	FRIEND_REQUEST_SEND_FAIL,
	FRIEND_REQUEST_SEND_SUCCESS,
	UNFRIEND_SEND,
	UNFRIEND_SEND_FAIL,
	UNFRIEND_SEND_SUCCESS
} from '../common/types';

// Handles one user sending a friend request to another
export const sendRelationshipRequest = (vals) => {
	return async(dispatch, getState) => {
		try {
			dispatch({ type: FRIEND_REQUEST_SEND });
			const payload = JSON.stringify(vals);
			const res = await axios.post('/relationships/requests/create/', payload);

			if(res.data.success) {
				dispatch({ 
					type: FRIEND_REQUEST_SEND_SUCCESS,
					payload: {
						request: res.data.request
					} 
				});
				return {
					success: true,
					request: res.data.request
				}
			}
			else {
				dispatch({ type: FRIEND_REQUEST_SEND_FAIL });
				return {
					success: false,
					errors: httpErrorHelper(401, res.data)
				};
			}
		}
		catch(e) {
			dispatch({ type: FRIEND_REQUEST_SEND_FAIL });
			return {
				success: false,
				errors: httpErrorHelper(e.status, e.response.data)
			}
		}
	};
};

// Handle the acceptance of a friend request
// This TECHNICALLY abuses what POST is supposed to do since it deletes the
// request and then makes multiple Relationship objects and returns an
// unrelated object (the new friend's profile), but honestly I refuse to believe
// that four separate HTTP calls are appropriate in this context, so this is what
// we get and I think the spec committees would forgive me for doing this.
export const acceptRelationshipRequest = (arrayIdx, req) => {
	return async(dispatch, getState) => {
		dispatch({ type: FRIEND_REQUEST_ACCEPT });
		const payload = JSON.stringify({ reqid: req.apid });
		try {
			const res = await axios.post(`/relationships/requests/accept/`, payload);
			if(res.data.success) {
				dispatch({ type:
					FRIEND_REQUEST_ACCEPT_SUCCESS,
					payload: {
						friend: res.data.friend,
						reqIdx: arrayIdx
					}
				});
				return {
					success: true,
					friend: res.data.friend,
				};
			}
			else {
				dispatch({ type: FRIEND_REQUEST_ACCEPT_FAIL });
				return {
					success: false,
					errors: httpErrorHelper(res.status, res.data)
				};
			}
		}
		catch(e) {
			console.log(e);
			dispatch({ type: FRIEND_REQUEST_ACCEPT_FAIL });
			return {
				success: false,
				errors: httpErrorHelper(e.response.status, e.response.data)
			};
		}
	};
};

// No, I deny you sir
export const declineRelationshipRequest = (arrayIdx, request) => {
	return async(dispatch, getState) => {
		try {
			dispatch({ type: FRIEND_REQUEST_DECLINE });
			// Deleting a friend request returns 204 on success so there's no response data to store
			await axios.delete(`/relationships/requests/decline/${request.apid}/`);
			dispatch({
				type: FRIEND_REQUEST_DECLINE_SUCCESS,
				payload: {
					index: arrayIdx
				}
			});
			return { success: true };
		}
		catch(e) {
			dispatch({ type: FRIEND_REQUEST_DECLINE_FAIL });
			console.log(e);
			return {
				success: false,
				errors: httpErrorHelper(e.response.status, e.response)
			}
		}
	};
};

// Oh wait, I don't actually want to be friends with them
export const rescindRelationshipRequest = (arrayIdx, request) => {
	return async(dispatch, getState) => {
		try {
			dispatch({ type: FRIEND_REQUEST_REVOKE });
			// Deleting a friend request returns 204 on success so there's no response data to store
			await axios.delete(`/relationships/requests/rescind/${request.apid}/`);
			dispatch({
				type: FRIEND_REQUEST_REVOKE_SUCCESS,
				payload: {
					index: arrayIdx
				}
			});
			return { success: true };
		}
		catch(e) {
			dispatch({ type: FRIEND_REQUEST_REVOKE_FAIL });
			console.log(e);
			return {
				success: false,
				errors: httpErrorHelper(e.response.status, e.response)
			}
		}
	};
};

export const terminateRelationship = (arrayIdx, profId) => {
	return async(dispatch, getState) => {
		dispatch({ type: UNFRIEND_SEND });
		try {
			await axios.delete(`/relationships/terminate/${profId}/`);
			dispatch({
				type: UNFRIEND_SEND_SUCCESS,
				payload: {
					index: arrayIdx
				}
			});
			return { success: true };
		}
		catch(e) {
			dispatch({ type: UNFRIEND_SEND_FAIL });
			return {
				success: false,
				errors: httpErrorHelper(e.response.status, e.response)
			};
		}
	};
};