/* Cards are used for informational display that doesn't necesarilly require user action
 * and shouldn't be manually dismissable like a notification or modal. Examples inclide 
 * experience-related information (new posts, lists of friends, etc.) and unrecoverable
 * errors. Look and feel of each card can be heavily customized by passing in components
 * instad of text, but this should be done sparingly to ensure a consistent SWX */ 
import {
    Avatar,
    Box,
    Card,
    CardBody,
    CardFooter,
    Text
} from 'grommet';
import { useState } from 'react';

export const ErrorCard = ({body, head='Error', foot=null, ...props}) => {
    return (
        <StandardCard
            body={<Text color='status-critical-text'>{body}</Text>}
            head={head}
            foot={foot}
            icon={props.icon || <i className='fas fa-bomb' />}
            borderColor='status-critical'
            headTextColor='status-critical-text'
            subHeadTextColor='status-critical-text'
            baseBackgroundColor='status-critical-bg'
            headBackgroundColor='status-critical-bg'
            {...props} />
    );
};

export const WarningCard = ({body, head='Warning', foot=null, ...props}) => {
    return (
        <StandardCard
            body={<Text color='status-warning-text'>{body}</Text>}
            head={head}
            foot={foot}
            icon={props.icon || <i className='fas fa-exclamation-triangle' />}
            borderColor='status-warning'
            headTextColor='status-warning-text'
            subHeadTextColor='status-warning-text'
            baseBackgroundColor='status-warning-bg'
            headBackgroundColor='status-warning-bg'
            headBottomBorder={{color: 'status-warning', side: 'bottom'}}
            {...props} />
    );
};

export const AltPersonCard = ({person, ...props}) => {
    return (
        <StandardCard
            customHead={
                <Box
                    direction='column'
                    fill='horizontal'
                    align='center'
                    margin={{vertical: 'xsmall'}}>
                
                    <Avatar
                        align='center'
                        flex={false}
                        justify='center'
                        round='full'
                        src='https://rosieshouse.org/wp-content/uploads/2016/06/avatar-large-square-705x705.jpg'
                        margin={{vertical: 'xsmall'}}
                        border={{color: 'brand' , size: 'small'}}
                        size="xlarge" />

                    <Text color='brand' weight='bold'>{person.profile.display_name}</Text> 
                </Box>
            }
            body={person.profile.bio || 'No bio provided.'}
            foot={props.foot} />
    );
};

export const PersonCard = ({person, ...props}) => {
    return (
        <StandardCard
            aria={`${person.profile.display_name} profile summary card`}
            body={
                <Box 
                    direction='row'
                    fill='horizontal'
                    align='center'
                    justify='start'
                    gap='small'>

                    <Avatar
                        align='center'
                        flex={false}
                        justify='center'
                        round='full'
                        src='https://rosieshouse.org/wp-content/uploads/2016/06/avatar-large-square-705x705.jpg'
                        margin={{vertical: 'xsmall'}}
                        border={{color: 'brand' , size: 'small'}}
                        size="xlarge" />
                    
                    <Box
                        direction='column'
                        fill='vertical'
                        align='start'
                        justify='start'
                        gap='small'>
                    
                        {props.body}
                    </Box>
                </Box>
            }
            foot={props.foot} />
    );
};

// The base card from which all other cards are derived. Also serves as the basic
// card that will be displayed to the user when information is desired.
export const StandardCard = ({body, head=null, foot=null, ...props}) => {
    const [borderColor] = useState(props.borderColor || 'brand');
    const [headTextColor] = useState(props.headTextColor || 'brand');
    const [subHeadTextColor] = useState(props.subHeadTextColor || 'text-xweak');
    const [baseBackgroundColor] = useState(props.baseBackgroundColor || 'background-front');
    const [headBackgroundColor] = useState(props.headBackgroundColor || 'background-contrast');

    return (
    <Box
        a11yTitle={props.aria || `${body} ${props.cardType} card`}
        direction='column'
        fill='horizontal'
        align='center'
        gap='medium'
        pad={{horizontal: 'medium'}}>

        <Card
            flex
            fill='horizontal'
            width={{max: 'large'}}
            background={baseBackgroundColor}
            elevation='large'
            border={{color: borderColor, size: 'small'}}>

            { props.customHead && (
                <CardBody>
                    fill='horizontal'
                    border={props.headBottomBorder || false}
                    background={headBackgroundColor}>

                    {props.customHead}
                </CardBody>
            )}
        
            { head && (
            <CardBody
                fill='horizontal'
                align='center'
                pad={{horizontal: 'small', vertical: 'xxsmall'}}
                border={props.headBottomBorder || false}
                background={headBackgroundColor}>

                <Box
                    direction='row'
                    fill='horizontal'
                    align='center'
                    justify='start'
                    gap='small' >
                    
                    {props.icon &&
                        <Text 
                            size='medium'
                            color={headTextColor}
                            weight='bold'>
                            
                            {props.icon}
                        </Text>
                    }

                    <Box direction='column' fill='horizontal'>
                        <Text
                            size='medium'
                            color={headTextColor}
                            weight='bold'>

                            {head}
                        </Text>

                        {props.subHead && (
                            <Text size='small' color={subHeadTextColor}>
                                {props.subHead}
                            </Text>
                        )}
                    </Box>

                    {props.headActions && (
                        <Box justify='end'>
                            {props.headActions}
                        </Box>
                    )}
                </Box>
            </CardBody>
            )}
    
            <CardBody
                fill='horizontal'
                align='center'
                justify='center'
                pad='small'>
                
                {body}
            </CardBody>    

            { foot && (
                <CardFooter
                    fill='horizontal'
                    pad='small'
                    border={{color: borderColor, side: 'top'}}>

                    {foot}
                </CardFooter>
            )}
        </Card>
    </Box>
    );
};