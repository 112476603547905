import { useEffect } from 'react';
import { 
    Box,
    Grommet,
} from 'grommet' ;
import { Route, Switch } from 'react-router-dom';
import store from './common/store';
import Main from './home/cmp_main';
import { loadApp } from './actions/app';
import LoginForm from './auth/cmp_login.js';
import InvitationResponse from './invitations/cmp_invite_response';
import AuthenticatedRoute from './common/cmp_authenticated_route';
import { fz_theme } from './common/theme.js';
import { fzAxiosInterceptors } from './common/axios';
import { DevEnvironmentWarning } from './common/cmp_env_specific';

function App() {
  useEffect(() => {
      fzAxiosInterceptors(store);
      store.dispatch(loadApp());
  });

  return (
    <Grommet full theme={fz_theme} style={{ minHeight: '-webkit-fill-available' }} background='background'>
            <Switch>
            <Route path='/register' children={InvitationResponse} />
            <Route exact path='/login/'>
        <Box fill 
            direction='row-responsive'
            align='center'
            justify='center'
            gap='medium'
            background='background'>
            {process.env.REACT_APP_FZ_ENV === "FZDEV" && <DevEnvironmentWarning />}
            <Box width={{'min': '50%'}}>
                <LoginForm />
            </Box>
            </Box>
            </Route>
            <AuthenticatedRoute path='/' component={ Main } />
        </Switch>
    </Grommet>
  );
}

export default App;
