import {
	AUTH_NO_TOKEN,
    USER_LOAD_SUCCESS,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    USER_REGISTRATION_SUCCESS,
    USER_REGISTRATION_FAIL,
} from '../common/types';

const initialState = {
	isProcessingNotificationAction: false,
	basicNotificationsStateGood: true,
	basicNotifications: null
};

export const notificationsReducer = (state=initialState, action) => {
	switch(action.type) {

		// Auth-related actions. My god I'm sick of writing these.
		case USER_LOAD_SUCCESS:
		case LOGIN_SUCCESS:
		case USER_REGISTRATION_SUCCESS:
			console.log(action.payload);
			return {
				...state,
				isProcessingNotificationAction: false,
				basicNotifications: action.payload.notifications.basic_notifications
			};
		case LOGOUT_SUCCESS:
			return {
				...state,
				isProcessingNotificationAction: false,
				basicNotifications: null
			}
		case AUTH_NO_TOKEN:
		case USER_REGISTRATION_FAIL:
		case LOGOUT_FAIL:
			return {
				...state,
				isProcessingNotificationAction: false,
				basicNotificationsStateGood: false,
				basicNotifications: null
			};
		default:
			return state;
	}
};