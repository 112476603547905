// Show the user's favorites. Fairly self-explanatory.
import { Box } from 'grommet';
import { HomepageFriendPostCard } from '../home/cmp_main_friendposts';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const SelfFavorites = () => {
	const friendPosts = useSelector((state) => state.friendPosts.posts);
	const [favPosts, setFavPosts] = useState([]);

	useEffect(() => {
		setFavPosts(friendPosts.filter((p) => p.is_fav === true));
	}, [friendPosts]);

	return (
		<>
		{favPosts.length > 0 && (
		<Box
			direction='column'
			fill='horizontal'
			gap='small'
			margin={{bottom: 'large'}}>

			{favPosts.map((post, index) => (
				<HomepageFriendPostCard
					key={`selfpage-fav-post-${index}`}
					post={post}
					index={index}
					isFav={false} />
			))}
		</Box>
		)}
		</>
	);
};

export default SelfFavorites;