import axios from '../common/axios';
import {
	PROFILE_UPDATE,
	PROFILE_UPDATE_FAIL,
	PROFILE_UPDATE_SUCCESS
} from '../common/types';
import { httpErrorHelper } from '../common/network/http';

export const updateSelfProfile = (vals) => {
	return async(dispatch, getState) => {
		dispatch({ type: PROFILE_UPDATE });
		const payload = JSON.stringify(vals);
		try {
			const res = await axios.patch('/users/profile/edit/high/', payload);
			if(res.data.success) {
				dispatch({
					type: PROFILE_UPDATE_SUCCESS,
					payload: {
						profile: res.data.profile
					}
				});
				return { success: true };
			}
			else {
				dispatch({ type: PROFILE_UPDATE_FAIL });
				return {
					success: false,
					errors: httpErrorHelper(res.status, res.data)
				};
			}
		}
		catch(e) {
			console.log(e);
			dispatch({ type: PROFILE_UPDATE_FAIL });
			return {
				success: false,
				errors: httpErrorHelper(e.status, e.response.data)
			};
		}
	};
};