import {
    Box,
    Button,
    Heading,
} from 'grommet';
import { useSelector } from 'react-redux';
import { Route, useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import SelfProfile from './cmp_profile';
import { SelfPosts } from '../posts/cmp_self_posts';
import SelfFriends from '../relationships/cmp_self_friends';
import SelfFavorites from './cmp_favs';

const SelfPage = (props) => {
    const user = useSelector((state) => state.user.user);
    const history = useHistory();
    const location = useLocation().pathname;
    const { path, url } = useRouteMatch();

    return (
        <Box
            direction='column'
            align='center'
            gap='small'
            fill='horizontal'>

            <Box
                direction='column'
                gap='xsmall'
                align='center'
                fill='horizontal'>

                <Heading margin='small'
                    size='small'>

                    {user.profile.display_name}
                </Heading>

                <Box
                    border={{color: 'brand', size: 'small'}}
                    direction='row-responsive'
                    between='justify'>
                    
                    <SelfPageButton
                        baseUrl={url}
                        targetUrl='profile'
                        title='Profile'
                        icon={<i className='fas fa-id-card' />}
                        location={location}
                        history={history} />

                    <SelfPageButton
                        baseUrl={url}
                        targetUrl='friends'
                        title='Friends'
                        icon={<i className='fas fa-users' />}
                        location={location}
                        history={history} />

                    <SelfPageButton
                        baseUrl={url}
                        targetUrl='favorites'
                        title='Favs'
                        icon={<i className='fas fa-star' />}
                        location={location}
                        history={history} />

                    <SelfPageButton
                        baseUrl={url}
                        targetUrl='posts'
                        title='Posts'
                        icon={<i className='fas fa-comment-alt' />}
                        location={location}
                        history={history} />
                </Box>
            </Box>
            <Route path={`${path}/profile`}>
                <SelfProfile />
            </Route>
            <Route path={`${path}/friends`}>
                <SelfFriends />
            </Route>
            <Route path={`${path}/posts`}>
                <SelfPosts />
            </Route>
            <Route path={`${path}/favorites`}>
                <SelfFavorites />
            </Route>
        </Box>
    );
};

const SelfPageButton = ({title, baseUrl, targetUrl, location, history, icon=null}) => {
    const fullTarget = `${baseUrl}/${targetUrl}`;

    return (
    <Button
        plain={true}
        hoverIndicator='background-contrast'
        disabled={location === fullTarget ? true : false}
        onClick={() => history.push(fullTarget)}>
            <Box
                direction='row'
                align='center'
                justify='center'
                gap='small'
                background={location === fullTarget ? 'brand' : null}
                pad={{vertical: 'xsmall', horizontal: 'medium'}}>
                {icon} 
                {title}
            </Box>
    </Button>
    );
}

export default SelfPage;
