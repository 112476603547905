// Build a date string from a server-provided UTC DateTime
export const buildDateString = (dateString, nullable=false) => {
	const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };
	const epoch = Date.parse(dateString);
	return (!epoch || isNaN(epoch))
		? (nullable ? null : 'Not Provided')
		: (new Date(epoch)).toLocaleDateString('en-US', options)
};

export const buildShortDateString = (dateString, nullable=false) => {
	const options = { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' };
	const epoch = Date.parse(dateString);
	return (!epoch || isNaN(epoch))
		? (nullable ? null : 'Not Provided')
		: (new Date(epoch)).toLocaleDateString('en-US', options)
};

// Build a location string from a user profile.
export const buildLocationString = (profile, nullable=false) => {
	if(!profile.city && !profile.state && !profile.country) {
		return nullable ? null : 'No location specified';
	}
	const loc = [];
	if(profile.city) {
		loc.push(profile.city);
	}
	if(profile.state) {
		loc.push(profile.state);
	}
	if(profile.country) {
		loc.push(profile.country);
	}
	return loc.join(', ');
}