import {
    Anchor,
    Box,
    Image,
    Text
} from 'grommet';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
    <Box
        direction='column'
        fill
        gap='medium'
        align='center'
        justify='center'>

        <Image size='medium'
            a11yTitle='FZ Logo'
            src='/assets/fz_logo.png' />

        <Text fill='horizontal'>
            The resource you requested could not be found. You may be attempting to access an invalid path, or the feature may not yet be implemented.
        </Text>
        
        <Link to='/'><Anchor a11yTitle='Link to home page.'>Go Home</Anchor></Link>
    </Box>
    );
};

const FormFieldError = ({field}) => {
    return(
        <ul>
            {field.map((v, i) => (
                <Text key={i} color='status-critical'>
                    <li key={`err-text-${i}`}>{v}</li>
                </Text>
            ))}
        </ul>       
    );
}

export { NotFound, FormFieldError };
