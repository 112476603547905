import _ from 'lodash';
import axios from '../common/axios';
import {httpErrorHelper} from '../common/network/http';

import {
    AUTH_ERROR,
    AUTH_NO_TOKEN, 
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT_FAIL,
    LOGOUT_SUCCESS,
    USER_LOAD,
    USER_LOAD_SUCCESS,

    CSRF_UPDATE,
    SESSION_GET_FAIL,
    SESSION_GET_LOGGED_OUT,
    SESSION_GET_SUCCESS
} from '../common/types';

// Because of the way Redux works, and because it's a pain in the ass to dispatch
// a function that dispatches other functions, these _get* functions are provided to
// be called where needed (for example, when all three must be called in succession
// during app load and their outputs affect the behavior of the others). If needed
// elsewhere, CALL THE WRAPPERS UNLESS YOU HAVE A GOOD REASON NOT TO.

// Because of how Django works, we need to reload the CSRF token on ALL HTTP (DO WE???)
// requests. That would result in duplicated code. This does not. This does not
// make a call - instead, it uses the new CSRF token that should be returned with
// all API calls, although if we don't get one we will retry it.
export const updateCsrfToken = async(dispatch, token) => {
    if(!token) {
        const res = await _getCsrf();
        token = res.data['X-CSRFToken'];
    }
    dispatch({
        type: CSRF_UPDATE,
        csrftoken: token
    });
};

// Get CSRF axios headers for requests, or return an
// empty list.
export const getCsrfHeaders = getState => {
    const csrftoken = getState().auth.csrftoken;

    if(!csrftoken) {
        return {};
    }
    else {
        return {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken
            }
        };
    }
};

// Function to get a CSRF token. Needs to be wrapped in something
// that can call dispatch() to interface with the store.
export const _getCsrf = async() => {
    try {
        const res = await axios.get('/users/get_csrf/');

        if(res.data.success) {
            return {
                success: true,
                csrftoken: res.data['X-CSRFToken']
            };
        }
        else {
            return {
                success: false,
                msg: res.data.msg
            };
        }
    }
    catch(e) {
        return {
            success: false,
            msg: e
        };
    }
};

// Function to obtain a session. Needs to be wrapped in something
// that can call dispatch() to interface with the store. Not boolean
// because of the pesky third option:
//      1. It worked
//      2. It worked, but the user isn't authenticate
//      3. It didn't work
export const _getSession = async() => {
    try {
        const res = await axios.get('/users/get_session/');

        // Success and session returned
        if(res.data.success && res.data.is_authenticated) {
            return {
                success: true,
                status: SESSION_GET_SUCCESS
            };
        }
        // Success but no session to find
        else if(res.data.success) {
            return {
                success: true,
                status: SESSION_GET_LOGGED_OUT
            };
        }
        // Actual failure
        else {
            return {
                success: true,
                status: SESSION_GET_FAIL
            };
        }
    }
    catch(e) {
        return {
            success: true,
            status: SESSION_GET_FAIL
        };
    }
};

// Gets the current user and returns their profile, their invitation manager,
// their pending invites, and their posts if successful. Basically does the same
// thing as login(), but for people who are already logged in (i.e. there's no
// server-side credential check or CSRF token update).
export const _getUser = async(getState) => {
    const headers = getCsrfHeaders(getState);
    if(_.isEmpty(headers)) {
        return {
            success: false,
            status: AUTH_NO_TOKEN
        };
    }

    try {
        const res = await axios.get('/users/getme/');
        if(res.data.user && res.data.user.profile && res.data.invitations && res.data.posts) {
            return {
                success: true,
                user: res.data.user,
                invitations: res.data.invitations,
                posts: res.data.posts,
                relationships: res.data.relationships,
                notifications: res.data.notifications,
                friend_posts: res.data.friend_posts
            };
        }
        else {
            return {
                success: false,
                errors: httpErrorHelper(res.status, res)
            };
        }
    }
    catch(e) {
        return {
            success: false,
            errors: httpErrorHelper(e.response.status, e.response.data)
        };
    }
};

// Get the current user. Wrapper with dispatch() calls for the above function.
// Use this whenever possible, unless there's a reason not to (e.g. the app load).
export const getUser = () => {
    return async (dispatch, getState) => {
        dispatch({ type: USER_LOAD });
        const res = await _getUser(getState);

        if(!res.success && res.status === AUTH_NO_TOKEN) {
            dispatch({ type: AUTH_NO_TOKEN });
            return { success: false };
        }
        else if(!res.success) {
            dispatch({ type: AUTH_ERROR });
            return { success: false };
        }
        else {
            dispatch({
                type: USER_LOAD_SUCCESS,
                payload: {
                    user: res.user,
                    invitations: res.invitations,
                    posts: res.posts,
                    relationships: res.relationships,
                    notifications: res.notifications,
                    friendPosts: res.friend_posts
                }
            });
            return { success: true };
        }
    };
}

// Log in. May be a bit more complex than it needs to be, but I'm trying to trade
// frequent on-demand loading for grabbing as much as possible up front. Gets the user,
// their posts, their invitation manager, and pending invites. Supports a variety of
/// exciting failure conditions and attempts to handle them all.
export const login = (username, password) => {
    return async (dispatch, getState) => {
        // Get headers or fail
        const axiosHeaders = getCsrfHeaders(getState);
        if(_.isEmpty(axiosHeaders)) {
            dispatch({ type: AUTH_NO_TOKEN });
            return {
                success: false,
                errors: {
                    non_field_errors: 'Client-side token issue.',
                    title: 'Non-HTTP Error'
                }
            }
        }

        try {
            const creds = JSON.stringify({username, password});
            const res = await axios.post('/users/login/', creds, axiosHeaders);
            
            // There's no real success code returned from the login API endpoing, 
            // so we're checking the following: user, user.profile, invitation_manager
            console.log(res.data);
            if(res.data.user && res.data.user.profile && res.data.invitations && res.data.posts && res.data.relationships) {
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: {
                        csrftoken: res.data['X-CSRFToken'],
                        user: res.data.user,
                        invitations: res.data.invitations,
                        posts: res.data.posts,
                        relationships: res.data.relationships,
                        notifications: res.data.notifications,
                        friendPosts: res.data.friend_posts
                    }
                });
                return { success: true };
            }
            else {
                dispatch({ type: LOGIN_FAIL });
                return {
                    success: false,
                    errors: httpErrorHelper(res.status, res)
                };
            }
        }
        catch(e) {
            dispatch({
                type: LOGIN_FAIL
            });
            return {
                success: false,
                errors: httpErrorHelper(e.response.status, e.response.data)
            };
        }
    }
};

// Logout. We catch a failure here just in case and may want to do something
// with it in the future, but for now we just sort of ignore it.
// Sets a CSRF token to allow the user to log right back in.
export const logout = () => {
    return async (dispatch, getState) => {
        const headers = getCsrfHeaders(getState);
        if(_.isEmpty(headers)) {
            dispatch({
                type: AUTH_NO_TOKEN
            });
        }
        else {
            try {
                console.log(headers);
                const res = await axios.get('/users/logout/', headers);
                dispatch({
                    type: LOGOUT_SUCCESS,
                    payload: {
                        csrftoken: res.data['X-CSRFToken']
                    }
                });
            }
            catch(e) {
                dispatch({
                    type: LOGOUT_FAIL,
                    payload: {
                        csrftoken: e.response.data['X-CSRFToken'] || null
                    }
                });
            }
        }
    };
}
