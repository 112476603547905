import {
	Box,
	Button,
	Form,
	FormField,
	Text,
	TextInput
} from 'grommet';
import { ErrorCard, PersonCard, StandardCard } from '../common/cmp_cards';
import {
	ErrorNotification,
	SuccessNotification,
	WarningNotification,
} from '../common/cmp_notifications';
import { ActionableModal, InformationalModal } from '../common/cmp_modals';
import { rescindRelationshipRequest, sendRelationshipRequest, terminateRelationship } from './actions';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// The base relationshipss area. Contains pending outbound requests, a form to send more
// requests, and an area where each current relationship is displayed as a card.
const SelfRelationships = ({props}) => {
	const userRelationships = useSelector((state) => state.userRelationships);

	return (
	<Box
        direction='column'
        align='center'
        gap='small'
        pad='small'
        fill='horizontal'>

		<SentRelationshipRequestArea reqs={userRelationships.pendingRequestsFrom} />

		<RelationshipRequestFormArea />

		<RelationshipsArea rels={userRelationships.friends} />
		
	</Box>
	);
};

// Outbound relationship requests that are still pending
const SentRelationshipRequestArea = ({reqs}) => {
	const [targetIndex, setTargetIndex] = useState(-1);
	const [targetRequest, setTargetRequest] = useState({});
	const [showRevocationModal, setShowRevocationModal] = useState(false);
	const [isRevoking, setIsRevoking] = useState(false);
	const [showRevokeSuccess, setShowRevokeSuccess] = useState(false);
	const [showRevokeFailure, setShowRevokeFailure] = useState(false);
	const [failureMsg, setFailureMsg] = useState('');
	const dispatch = useDispatch();

	const modalConfig = (index, request) => {
		setShowRevocationModal(true);
		setTargetIndex(index);
		setTargetRequest(request);
	};

	const handleRevocationConfirmation = () => {
		setShowRevokeSuccess(false);
		setIsRevoking(true);
		dispatch(rescindRelationshipRequest(targetIndex, targetRequest), [dispatch]).then((res) => {
			if(res.success) {
				setShowRevokeSuccess(true);
				setTargetIndex(-1);
				setTargetRequest({});
			}
			else {
				const newError = res.errors.non_field_errors || `HTTP ${res.errors.http.code}: ${res.errors.http.msg}`;
				setFailureMsg(newError);
				setShowRevokeFailure(true);
			}
		});
		setShowRevocationModal(false);
		setIsRevoking(false);
	};

	return (
		<>
		{reqs && reqs.length > 0 ? (
			<Box
            fill='horizontal'
            gap='small'>

            	{reqs.map((request, index) => (
            		<SentRelationshipRequestCard
            			key={index}
            			request={request}
            			index={index} 
            			modalConfigFn={modalConfig} />
            	))}
            </Box>
		) : (
			<StandardCard body='You do not have any relationship requests waiting on someone else to respond.' />
		)}

		<ActionableModal
			head='Revoke relationship request?'
			body='If you revoke this relationship request, either you or the recipient will have to send a new one to connect. Please be aware that the recipient may be aware that you set this request (for example, if they have seen it and have not responded yet) and therefore may be able to infer that you cancelled it.'
			actions={
				<Button
					primary
					color='status-critical'
					label='Revoke'
					disabled={isRevoking ? true : false}
					icon={isRevoking
						? <i className='fas fa-spin fa-spinner' />
						: <i className='fas fa-user-slash' />}
					onClick={handleRevocationConfirmation} />
			}
			show={showRevocationModal}
			setShow={setShowRevocationModal}
			lockout={isRevoking} />

		<WarningNotification
			msg='Relationship request revoked successfully.'
			show={showRevokeSuccess}
			setShow={setShowRevokeSuccess}
			icon={<i className='fas fa-circle-check' />}
			timeout />

		<ErrorNotification
			msg={failureMsg}
			show={showRevokeFailure}
			setShow={setShowRevokeFailure}
			timeout={5000} />
		</>
	);
};

// Card used for each outbound relationship request
const SentRelationshipRequestCard = ({index, request, modalConfigFn}) => {

	const handleRequestRevokeClick = () => {
		modalConfigFn(index, request);
	}

	return (
		<StandardCard
			head={`Sent on ${request.created}:`}
			body={
				<Box
					direction='row'
					fill='horizontal'
					gap='small'>
					
					<Text>To:</Text>
					<Text weight='bold'>{request.recipient.profile.code}</Text>		
				</Box>
			}
			foot={
				<Box
					direction='row'
					fill='horizontal'
					justify='end'>
				
					<Button
						color='status-critical'
						secondary
						icon={<i className='fas fa-user-slash' />}
						label='Revoke' 
						onClick={handleRequestRevokeClick} />
				</Box>
			}
			/>
	);
};

// Area to display form to send requests
const RelationshipRequestFormArea = ({props}) => {
	const [showInfoModal, setShowInfoModal] = useState(false);

	const toggleInfoModal = () => {
		setShowInfoModal(!showInfoModal);
	};

	return (
		<>
		<StandardCard 
			head={<Text weight='bold' color='brand'>Send Relationship Request</Text>}
			body={<RelationshipRequestForm />} 
			headActions={
				<Button
					a11yTitle='Relationship request privacy information'
					plain
					color='brand'
					icon={<i className='fas fa-info-circle' />}
					onClick={toggleInfoModal} />
			} />

		<InformationalModal
			body={`Sending a relationship request to someone will expose some typically-private information. The recipient will be able to see your first name, last name, and profile bio. You will not be able to see any of their private information until they accept the request. Only send a request if you're okay with this.`}
			head={'Privacy Information'}
			show={showInfoModal}
			setShow={setShowInfoModal} />
		</>
	);
};

// Form to send requests
const RelationshipRequestForm = ({props}) => {
	const [formValues, setFormValues] = useState({code: ''});
	const [formErrors, setFormErrors] = useState({});
	const [isSendingRequest, setIsSendingRequest] = useState(false);
	const [showSuccessNotification, setShowSuccessNotification] = useState(false);
	const dispatch = useDispatch();

	const handleFormChanged = (newValues) => {
		setFormValues({
			...newValues,
			code: newValues.code.toUpperCase()
		})
	};

	const handleFormReset = () => {
		setFormValues({ code: '' });
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		setIsSendingRequest(true);
		setShowSuccessNotification(false);
		setFormErrors({});
		if(formValues.code.length < 4 || formValues.code.length > 10) {
			setFormErrors({ code: 'Enter a valid Z-Code' });
		}
		else {
			dispatch(sendRelationshipRequest(formValues), [dispatch]).then((res) => {
				if(res.success) {
					setShowSuccessNotification(true);
					setFormValues({ code: '' });
				}
				else {
					setFormErrors(res.errors);
				}
			});
		}
		setIsSendingRequest(false);
	}

	return (
		<Form
			value={formValues}
			errors={formErrors}
			onChange={newValues => handleFormChanged(newValues)}
			onReset={handleFormReset}
			onSubmit={handleFormSubmit}
			style={{width: '100%'}}>

			{formErrors.non_field_errors && (
				<ErrorCard 
					body={formErrors.non_field_errors} />
			)}
		
			<FormField
				a11yTitle='Form field for Z-Code'
				label='Z-Code'
				placeholder='CODEHERE'
				name='code'
				style={{width: '100%'}}
				as={TextInput} />

			<Box align='center'> 
               	<Button
                   	type='submit'
                    a11yTitle='Send relationship request button'
                    icon={isSendingRequest
                        ? <i className='fas fa-spin fa-spinner' />
                        : <i className='far fa-heart' />}
                    label='Send Request'
                    active={false}
                    size='medium'
                    plain={false}
                    disabled={(formValues.code.length === 0 || isSendingRequest)
                        ? true
                        : false}
                    primary />
            </Box>

            <SuccessNotification
            	show={showSuccessNotification}
            	setShow={setShowSuccessNotification}
            	msg='Relationship request sent successfully.'
            	timeout={5000} />
		</Form>
	);
};

// Area for current relationships
const RelationshipsArea = ({rels}) => {
	const [isProcessingDisconnect, setIsProcessingDisconnect] = useState(false);
	const [showDisconnectModal, setShowDisconnectModal] = useState(false);
	const [showSuccessNotification, setShowSuccessNotification] = useState(false);
	const [showErrorNotification, setShowErrorNotification] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [targetProfileIdx, setTargetProfileIdx] = useState(-1);
	const [targetUserProfile, setTargetUserProfile] = useState({});
	const dispatch = useDispatch();

	const toggleDisconnectModal = () => {
		setShowDisconnectModal(!showDisconnectModal);
	};

	const handleDisconnectRequest = (idx, prof) => {
		toggleDisconnectModal();
		setTargetProfileIdx(idx);
		setTargetUserProfile(prof);
	};

	const handleDisconnectConfirm = () => {
		setIsProcessingDisconnect(true);
		dispatch(terminateRelationship(targetProfileIdx, targetUserProfile.apid), [dispatch]).then((res) => {
			toggleDisconnectModal();
			if(res.success) {
				setShowSuccessNotification(true);
			}
			else {
				setErrorMessage(res.errors.non_field_errors || `HTTP ${res.errors.http.code}: ${res.errors.http.msg}`);
				setShowErrorNotification(true);
			}
		});
		setIsProcessingDisconnect(false);
	};

	return (
	<Box
		direction='column'
		fill='horizontal'
		gap='small'>
			
		{rels.map((rel, index) => {
			return (
				<SelfRelationshipCard
					key={`self-relationship-card-${index}`}
					index={index}
					ring={rel.privilege_ring}
					person={rel.target} 
					disconnectFn={handleDisconnectRequest} />
				);
			})}

		<ActionableModal
			show={showDisconnectModal}
			setShow={toggleDisconnectModal}
			head={'Delete Relationship?'}
			body={`Are you sure you want to disconnect from ${targetUserProfile.display_name}? If you do so, the relationship will be deleted. They will not be notified of this, but it's easy to infer that it happened. If you don't want to risk starting anything, you can move them to a less-privileged ring (not yet implemented) or soft-block them (not yet implemented).`}
			actions={
				<Button
					primary
					color='status-critical'
					label='End It!'
					disabled={isProcessingDisconnect}
					icon={isProcessingDisconnect
						? <i className='fas fa-spin fa-spinner' />
						: <i className='fas fa-user-slash' />} 
					onClick={handleDisconnectConfirm} />
				}
			lockout={isProcessingDisconnect} />

		<WarningNotification
			show={showSuccessNotification}
			setShow={setShowSuccessNotification}
			msg='Relationship terminated.'
			icon={<i className='fas fa-circle-check' />}
			timeout={5000} />

		<ErrorNotification
			show={showErrorNotification}
			setShow={setShowErrorNotification}
			msg={errorMessage}
			timeout={5000} />

	</Box>
	);
};

// Card for displaying an individual current relationship
const SelfRelationshipCard = ({index, person, ring, disconnectFn, ...props}) => {
	const handleDisconnectClick = () => {
		disconnectFn(index, person.profile);
	};

	return (
		<>
		<PersonCard
			person={person} 
			body={
				<>
				<Text color='brand' weight='bold' size='large'>{person.profile.display_name}</Text>
				<Text color='text-xweak' size='small'>Ring {ring} Relationship</Text>
				<Text>{person.profile.bio || 'No profile bio provided'}</Text>
				</>
			}
			foot={
				<Box
					direction='row-responsive'
					fill='horizontal'
					justify='end'
					gap='medium'>

					<Button
						disabled
						secondary
						color='brand'
						label='View'
						icon={<i className='fas fa-user' />}
						onClick={() => {}} />
						
					<Button
						disabled
						secondary
						color='brand'
						label='Manage'
						icon={<i className='fas fa-user-cog' />}
						onClick={() => {}} />

					<Button
						secondary
						color='status-critical'
						label='Disconnect'
						icon={<i className='fas fa-user-slash' />}
						onClick={handleDisconnectClick} />
				</Box>
			} />
		</>
	);
};

export default SelfRelationships;