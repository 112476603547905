import {
    Box,
    Header,
    Image,
    Menu,
} from 'grommet';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { 
    DevEnvironmentAppBarLogo,
    TestEnvironmentAppBarLogo,
} from './cmp_env_specific';

import { logout } from '../actions/auth';

const AppBar = (props) => {
    const user = useSelector((state) => state.user.user);
    return (
    <Header
        a11yTitle='Navigation bar'
        background='brand'
        pad={{'left': 'medium', 'right': 'medium', 'top': 'small', 'bottom': 'small'}}
        elevation='medium'
        fill='horizontal'
        align='center'
        height='60px'>
        <Box 
            direction='row'
            gap='small'
            align='center'
            justify='center'>
            
            <Link to='/'>
                <Box
                    height='xxsmall'
                    width='xxsmall'>
            
                    <Image 
                        a11yTitle='Link to Home via FZ logo'
                        fit='cover'
                        src='/assets/fz_logo_invert.png' />
                </Box>
            </Link>
            { process.env.REACT_APP_FZ_ENV === 'FZDEV' && <DevEnvironmentAppBarLogo /> }
            { process.env.REACT_APP_FZ_ENV === 'FZTEST' && <TestEnvironmentAppBarLogo />}
        </Box>
        <Box direction='row' justify='end' gap='large'>
            <UserMenuButton fname={user.first_name} />
        </Box>
    </Header>
    );
};

const UserMenuButton = ({fname}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const handleLogout = (e) => {
        e.preventDefault();
        dispatch(logout(), [dispatch]);
    };

    return (
    <Box align='center' pad='large'>
    <Menu
        a11yTitle='User controls menu'
        label={<Box direction='row' align='center' gap='small'>
            <i className='fas fa-user-circle' />
            {fname}
        </Box>}
        icon={<i className='fas fa-caret-down' />}
        dropProps={{ a11yTitle: 'User control menu options', align: { top: 'bottom' }, elevation: 'large' }}
        dropBackground='background-front'
        plain
        items={[
            { label:
                <Box
                    a11yTitle='Return to home'
                    direction='row'
                    align='center'
                    gap='small'>

                    <i className='fas fa-home' />
                    Home
                </Box>,
                onClick: () => history.push('/')
            },
            { label:
                <Box
                    a11yTitle='View your profile'
                    direction='row'
                    align='center'
                    gap='small'>

                    <i className='fas fa-id-card' />
                    {`${fname}'s Info`}
                </Box>,
                onClick: () => history.push('/me/profile')
            },
            { label:
                <Box
                    a11yTitle='Manage invitations'
                    direction='row'
                    align='center'
                    gap='small'>

                    <i className='fas fa-paper-plane' />
                    Invitations
                </Box>,
                onClick: () => history.push('/invitations')
            },
            { label:
                <Box
                    a11yTitle='Log out of FZ'
                    direction='row'
                    align='center'
                    gap='small'>

                    <i className='fas fa-power-off' />
                    Log Out
                </Box>,
                onClick: handleLogout
            }
        ]} />
    </Box>
    );
};

export default AppBar;
