import { Box, Button } from 'grommet';
import { StandardCard } from '../common/cmp_cards';
import { ErrorNotification } from '../common/cmp_notifications';

import { favoritePost } from '../posts/actions';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const HomepageFriendPostArea = () => {
	const friendPosts = useSelector((state) => state.friendPosts.posts);

	return (
	<>
	{friendPosts.length > 0 && (
		<Box
			direction='column'
			fill='horizontal'
			gap='small'
			margin={{bottom: 'large'}}>

			{friendPosts.map((post, index) => (
				<HomepageFriendPostCard
					key={`homepage-friend-post-${index}`}
					post={post}
					index={index}
					isFav={false} />
			))}
				
		</Box>
	)}
	</>
	);
};

// Exported for use in the favs area as well.
export const HomepageFriendPostCard = ({post, index, isFav, ...props}) => {
	const [fav, setFav] = useState(post.is_fav);
	const [isFavProcessing, setIsFavProcessing] = useState(false);
	const [showError, setShowError] = useState(false);
	const dispatch = useDispatch();

	const togglePostFavorite = () => {
		setIsFavProcessing(true);
		dispatch(favoritePost(index, post.apid, fav), [dispatch]).then((res) => {
			if(res.success) {
				setFav(!fav);
			}
			else {
				setShowError(true);
			}
		});
		setIsFavProcessing(false);
	};

	return (
		<>
		<StandardCard
			key={`homepage-friend-post-${index}`}
			head={`${post.creator.profile.display_name} said:`}
			body={
				<Box
					direction='row'
					gap='small'
					fill='horizontal'>
							
					{post.content}		
				</Box>
			} 
			foot={
				<Box
					direction='row-responsive'
					fill='horizontal'
					align='center'
					justify='end'
					gap='medium'>
									
					<Button
						secondary
						color='brand'
						label={fav
							? <i className={`fas ${isFavProcessing && 'fa-spin'} fa-star`} />
							: <i className={`far ${isFavProcessing && 'fa-spin'} fa-star`} />
						}
						onClick={togglePostFavorite} />
					</Box>
				}/>

		<ErrorNotification
			msg={`Error ${fav ? 'removing post from' : 'adding post to'} your favorites.`}
			show={showError}
			setShow={setShowError}
			timeout={5000} />
		</>
	);
};

export default HomepageFriendPostArea;