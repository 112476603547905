import {
    Box,
    Heading,
} from 'grommet';
import QuickPost from './cmp_quickpost';
import HomepageNotificationArea from './cmp_main_notifications';
import PendingFriendRequestsToArea from './cmp_main_friendrequest';
import HomepageFriendPostArea from './cmp_main_friendposts';
import AppBar from '../common/cmp_app_bar';
import SelfPage from '../base/cmp_self_page';
import InvitationManager from '../invitations/cmp_user_invite_manager.js';
import {NotFound} from '../common/components/errors';
import {useSelector} from 'react-redux';
import {Route, Switch} from 'react-router-dom';

const Main = (props) => {
    const user = useSelector((state) => state.user.user);
        
    return (
    <>
    <AppBar />
    <Switch>
    <Route exact path='/'>
    <Box
        direction='column'
        align='center'
        gap='medium'
        background='background'>

        <Heading margin='small' size='small'>Welcome, {user.first_name}</Heading>
        
        <Box direction='column'
            fill='horizontal'
            align = 'center'
            gap = 'none'
            pad = {{'horizontal': 'small'}}
            margin={{bottom: 'small'}}>

            <HomepageNotificationArea />

            <HomepageFriendPostArea />

            <PendingFriendRequestsToArea />

            <QuickPost />
        
        </Box>
    </Box>
    </Route>
    <Route path='/home'>
    <Box><Heading>Test test test</Heading></Box>
    </Route>
    <Route path='/me'>
        <SelfPage />
    </Route>
    <Route path='/invitations'>
        <InvitationManager />
    </Route>
    <Route path='*'>
        <NotFound />    
    </Route>
    </Switch>
    </>
    );
};

export default Main;