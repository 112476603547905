import {
    LOGIN_SUCCESS,
    FAVORITE_POST,
    FAVORITE_POST_FAIL,
    FAVORITE_POST_SUCCESS,
    MY_POSTS_GET,
    MY_POSTS_GET_FAIL,
    MY_POSTS_GET_SUCCESS,
    POST_DELETE,
    POST_DELETE_FAIL,
    POST_DELETE_SUCCESS,
    POST_SEND,
    POST_SEND_FAIL,
    POST_SEND_SUCCESS,
    POST_UPDATE,
    POST_UPDATE_FAIL,
    POST_UPDATE_SUCCESS,
    USER_LOAD_SUCCESS,
} from '../common/types.js';

const userPostsInitialState = {
    isConsistent: true,
    isDeletingPost: false,
    isGettingPost: false,
    isPosting: false,
    recent: null,
};

const friendPostsInitialState = {
    isConsistent: true,
    isFavoritingPost: false,
    posts: null
}

export const userPostsReducer = (state = userPostsInitialState, action) => {
    switch(action.type) {
        case MY_POSTS_GET:
            return {
                ...state,
                isGettingPost: true
            };
        case MY_POSTS_GET_FAIL: 
            return {
                ...state,
                isConsistent: false,
                isGettingPost: false,
            };
        case MY_POSTS_GET_SUCCESS:
        case LOGIN_SUCCESS:
        case USER_LOAD_SUCCESS:
            return {
                ...state,
                isConsistent: true,
                isGettingPost: false,
                recent: action.payload.posts.recent,
            }
        case POST_DELETE:
            return {
                ...state,
                isDeletingPost: true
            };
        case POST_DELETE_FAIL:
            return {
                ...state,
                isConsistent: false,
                isDeletingPost: false,
            };
        case POST_DELETE_SUCCESS:
            return {
                ...state,
                isDeletingPost: true,
                recent: [...state.recent.slice(0, action.payload.index),
                            ...state.recent.slice(action.payload.index + 1)]
            }
        case POST_SEND:
            return {
                ...state,
                isSendingPost: true
            };
        case POST_SEND_FAIL:
            return {
                ...state,
                isSendingPost: false
            };
        case POST_SEND_SUCCESS:
            return {
                ...state,
                isSendingPost: false,
                recent: [action.payload.post, ...state.recent]
            }
        case POST_UPDATE:
            return {
                ...state,
                isPosting: true
            };
        case POST_UPDATE_FAIL:
            return {
                ...state,
                isPosting: false
            };
        case POST_UPDATE_SUCCESS:
            return {
                ...state,
                isPosting: false,
                recent: [...state.recent.slice(0, action.payload.index),
                                action.payload.post,
                                ...state.recent.slice(action.payload.index + 1)]
            };
        default:
            return state;
    }
};

export const friendPostsReducer = (state=friendPostsInitialState, action) => {
    switch(action.type) {
        case FAVORITE_POST:
            return {
                ...state,
                isFavoritingPost: true
            };
        case FAVORITE_POST_FAIL:
            return {
                ...state,
                isConsistent: false,
                isFavoritingPost: false
            };
        case FAVORITE_POST_SUCCESS:
            return {
                ...state,
                isFavoritingPost: false,
                posts: state.posts.map((p, i) => (
                    i === action.payload.index ? {...p, is_fav: !p.is_fav} : p
                ))
            };
        case LOGIN_SUCCESS:
        case USER_LOAD_SUCCESS:
            return {
                ...state,
                isConsistent: true,
                isGettingPost: false,
                posts: action.payload.friendPosts
            };
        default:
            return state;
    }
};
