import {
	APP_LOADING,
	APP_LOAD_FAIL,
	APP_LOAD_SUCCESS,
	CSRF_GET,
	CSRF_GET_FAIL,
	CSRF_GET_SUCCESS,
	SESSION_GET,
	SESSION_GET_LOGGED_OUT,
	SESSION_GET_FAIL,
	SESSION_GET_SUCCESS,
	USER_LOAD,
	USER_LOAD_SUCCESS,
	AUTH_ERROR
} from '../common/types';

import {
	_getCsrf,
	_getSession,
	_getUser,
} from '../actions/auth';

// Initial load of the app itself. Any failures here are critical errors that
// should stop the app from loading.
export const loadApp = () => {
	return async(dispatch, getState) => {
		var hasSession = false;
		dispatch({ type: APP_LOADING });

		// Try to get a session and bail with a critical error if we can't
		dispatch({ type: SESSION_GET });
		const sessionStatus = await _getSession();
		if(sessionStatus.success && sessionStatus.status === SESSION_GET_SUCCESS) {
			dispatch({ type: SESSION_GET_SUCCESS });
			hasSession = true;
		}
		else if(sessionStatus.success) {
			dispatch({ type: SESSION_GET_LOGGED_OUT });
		}
		else {
			dispatch({ type: SESSION_GET_FAIL });
			dispatch({ type: APP_LOAD_FAIL });
			return { success: false };
		}

		// Try to get a CSRF token and fail with a critical error if we can't
		dispatch({ type: CSRF_GET });
		const tokenStatus = await _getCsrf();
		console.log(tokenStatus);
		if(tokenStatus.success) {
			dispatch({
				type: CSRF_GET_SUCCESS,
				payload: { csrftoken: tokenStatus.csrftoken }
			});
		}
		else {
			dispatch({ type: CSRF_GET_FAIL });
			dispatch({ type: APP_LOAD_FAIL });
			return { success: false };
		}

		// If we have a session, we need to get the user
		console.log(hasSession);
		if(hasSession) {
			dispatch({ type: USER_LOAD });
			const userStatus = await _getUser(getState);
			if(userStatus.success) {
				dispatch({
					type: USER_LOAD_SUCCESS,
					payload: {
						user: userStatus.user,
						invitations: userStatus.invitations,
						posts: userStatus.posts,
						relationships: userStatus.relationships,
						notifications: userStatus.notifications,
						friendPosts: userStatus.friend_posts
					}
				});
			}
			else {
				dispatch({ type: AUTH_ERROR });
				return { success: false};
			}
		}

		dispatch({ type: APP_LOAD_SUCCESS });
		return {success: true };
	};
};