import {
    AUTH_NO_TOKEN,
    USER_LOAD,
    USER_LOAD_SUCCESS,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    PROFILE_UPDATE,
    PROFILE_UPDATE_FAIL,
    PROFILE_UPDATE_SUCCESS,
    USER_REGISTRATION_SUCCESS,
    USER_REGISTRATION_FAIL,
    UPDATE_INVITE_DATA_SUCCESS,
    UPDATE_INVITE_DATA_FAIL
} from '../common/types';

const initialState = {
    isLoading: true,
    userStateGood: true,
    isProcessingUserAction: false,
    user: null
};

export const userReducer = (state = initialState, action) => {
    switch(action.type) {
        case PROFILE_UPDATE:
            return {
                ...state,
                isProcessingUserAction: true
            };
        case PROFILE_UPDATE_FAIL:
            return {
                ...state,
                isProcessingUserAction: false,
                userStateGood: false
            };
        case PROFILE_UPDATE_SUCCESS:
            return {
                ...state,
                isProcessingUserAction: false,
                user: {
                    ...action.payload.user,
                    profile: action.payload.profile
                }
            };
        case USER_LOAD:
            return {
                ...state,
            };
        case LOGIN_SUCCESS:
        case USER_LOAD_SUCCESS:
        case USER_REGISTRATION_SUCCESS:
            return {
                ...state,
                user: action.payload.user
            };
        case AUTH_NO_TOKEN:
        case LOGOUT_SUCCESS:
        case LOGOUT_FAIL:
        case USER_REGISTRATION_FAIL:
        case UPDATE_INVITE_DATA_FAIL:
            return {
                user: null
            };
        default:
            return state;
    }
};
