/* These are friend requests to which the user needs to responsd. They're
 * based on the StandardCard but are hidden once responded to and depend on
 * an action being performed. */
import { Box, Button } from 'grommet';
import { StandardCard } from '../common/cmp_cards';
import { ErrorNotification, SuccessNotification, WarningNotification } from '../common/cmp_notifications';
import { acceptRelationshipRequest, declineRelationshipRequest } from '../relationships/actions';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const PendingRelationshipRequestsToArea = ({...props}) => {
	const [status, setStatus] = useState('');
	const [name, setName] = useState('');

	const reqs = useSelector((state) => state.userRelationships.pendingRequestsTo);

	const hideNotification = () => {
		setStatus('');
	};
	
	return (
		<>
		<SuccessNotification
			show={status === 'ACCEPT'}
			setShow={hideNotification}
			msg={`Accepted friend request from ${name}`}
			timeout={5000} />

		<WarningNotification
			show={status === 'DECLINE'}
			setShow={hideNotification}
			msg={`Declined friend request.`}
			icon={<i className='fas fa-circle-check' />}
			timeout={5000} />

		<ErrorNotification
			show={status === 'ERROR'}
			setShow={hideNotification}
			msg={`${name}`}
			timeout={5000} />

		{reqs.length > 0 && (
		<Box
			direction='column'
			gap='small'
			fill='horizontal'
			margin={{bottom: 'medium'}}>

			{reqs.map((req, index) => (
				<PendingRelationshipRequestToCard
					key={`request-to-card-${index}`} 
					req={req}
					index={index}
					setStatusFn={setStatus}
					setNameFn={setName} />
			))}
		</Box>
		)}
		</>
	);
};

const PendingRelationshipRequestToCard = ({req, index, setStatusFn, setNameFn}) => {
	const [isAccepting, setIsAccepting] = useState(false);
	const [isDeclining, setIsDeclining] = useState(false);
	const dispatch = useDispatch();

	const handleRelationshipRequestAccept = () => {
		setStatusFn('');
		setIsAccepting(true);
		dispatch(acceptRelationshipRequest(index, req), [dispatch]).then((res) => {
			if(res.success) {
				setNameFn(`${req.creator.first_name} ${req.creator.last_name}`);
				setStatusFn('ACCEPT');
			}
			else {
				setNameFn(res.errors.non_field_errors || `HTTP ${res.errors.title}`);
				setStatusFn('ERROR');
			}
		});
		setIsAccepting(false);
	}

	const handleRelationshipRequestDecline = () => {
		setStatusFn('');
		setIsDeclining(true);
		dispatch(declineRelationshipRequest(index, req), [dispatch]).then((res) => {
			if(res.success) {
				setStatusFn('DECLINE');
			}
			else {
				setNameFn(res.errors.non_field_errors || `HTTP ${res.errors.title}`);
				setStatusFn('ERROR');
			}
		});
		setIsDeclining(false);
	};

	return (
	<StandardCard
		head={`Relationship request from ${req.creator.first_name} ${req.creator.last_name}`} 
		subHead={`Sent on ${req.created}`}
		body={<Box fill='horizontal'>{req.creator.bio || 'No user bio available.'}</Box>}
		foot={
			<Box 
				direction='row-responsive'
				fill='horizontal'
				justify='end'
				gap='small'>
				
				<Button
					secondary
					color='status-critical'
					disabled={isAccepting || isDeclining}
					icon={isDeclining
						? <i className='fas fa-spin fa-spinner' />
						: <i className='fas fa-user-slash'/>
					}
					label='Decline' 
					onClick={handleRelationshipRequestDecline} />

				<Button
					primary
					color='status-ok'
					disabled={isAccepting || isDeclining}
					icon={isAccepting
						? <i className='fas fa-spin fa-spinner' />
						: <i className='fas fa-user-plus' />
					}
					label='Accept' 
					onClick = {handleRelationshipRequestAccept} />
			</Box>
		}
		/>
	);
};

export default PendingRelationshipRequestsToArea;