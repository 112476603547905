import {
	FRIEND_REQUEST_ACCEPT,
	FRIEND_REQUEST_ACCEPT_FAIL,
	FRIEND_REQUEST_ACCEPT_SUCCESS,
	FRIEND_REQUEST_DECLINE,
	FRIEND_REQUEST_DECLINE_FAIL,
	FRIEND_REQUEST_DECLINE_SUCCESS,
	FRIEND_REQUEST_REVOKE,
	FRIEND_REQUEST_REVOKE_FAIL,
	FRIEND_REQUEST_REVOKE_SUCCESS,
	FRIEND_REQUEST_SEND,
	FRIEND_REQUEST_SEND_FAIL,
	FRIEND_REQUEST_SEND_SUCCESS,
	UNFRIEND_SEND,
	UNFRIEND_SEND_FAIL,
	UNFRIEND_SEND_SUCCESS,
	LOGIN_SUCCESS,
	LOGOUT_SUCCESS,
	LOGOUT_FAIL,
	USER_LOAD_SUCCESS,
	USER_LOAD_FAIL
} from '../common/types';

const initialState = {
	isLoadingFriendshipData: false,
	isProcessingFriendshipAction: false,
	friends: null,
	friendStateGood: true,
	pendingRequestsTo: null,
	pendingRequestsToStateGood: true,
	pendingRequestsFrom: null,
	pendingRequestsFromStateGood: true
};

export const relationshipReducer = (state=initialState, action) => {
	switch(action.type) {
		case FRIEND_REQUEST_ACCEPT:
			return {
				...state,
				isProcessingFriendshipAction: true
			};
		case FRIEND_REQUEST_ACCEPT_FAIL:
			return {
				...state,
				isProcessingFriendshipAction: false
			};
		case FRIEND_REQUEST_ACCEPT_SUCCESS:
			return {
				...state,
				isProcessingFriendshipAction: false,
				// Sorting this will be a pain. Put it at the front for now, call it the "new friends
				// first policy" or some shit, then display the sorted version on refresh.
				friends: [action.payload.friend, ...state.friends],
				pendingRequestsTo: [...state.pendingRequestsTo.slice(0, action.payload.reqIdx),
									...state.pendingRequestsTo.slice(action.payload.reqIdx + 1)]
			};
		case FRIEND_REQUEST_DECLINE:
			return {
				...state,
				isProcessingFriendshipAction: true
			};
		case FRIEND_REQUEST_DECLINE_FAIL:
			return {
				...state,
				isProcessingFriendshipAction: false
			};
		case FRIEND_REQUEST_DECLINE_SUCCESS:
			console.log(`Reducer deleting request from local at idx ${action.payload.index}`);
			return {
				...state,
				isProcessingFriendshipAction: false,
				pendingRequestsTo: [...state.pendingRequestsTo.slice(0, action.payload.index),
									...state.pendingRequestsTo.slice(action.payload.index + 1)]
			};
		case FRIEND_REQUEST_REVOKE:
			return {
				...state,
				isProcessingFriendshipAction: true
			};
		case FRIEND_REQUEST_REVOKE_FAIL:
			return {
				...state,
				isProcessingFriendshipAction: false,
				pendingRequestsFromStateGood: false
			}
		case FRIEND_REQUEST_REVOKE_SUCCESS:
			return {
				...state,
				pendingRequestsFrom: [...state.pendingRequestsFrom.slice(0, action.payload.index),
										...state.pendingRequestsFrom.slice(action.payload.index + 1)]
			}
		case FRIEND_REQUEST_SEND:
			return {
				...state,
				isProcessingFriendshipAction: true
			};
		case FRIEND_REQUEST_SEND_FAIL:
			return {
				...state,
				isProcessingFriendshipAction: false
			};
		case FRIEND_REQUEST_SEND_SUCCESS:
			return {
				...state,
				isProcessingFriendshipAction: false,
				pendingRequestsFrom: [action.payload.request, ...state.pendingRequestsFrom]
			};
		case UNFRIEND_SEND:
			return {
				...state,
				isProcessingFriendshipAction: false
			};
		case UNFRIEND_SEND_FAIL:
			return {
				...state,
				friendStateGood: false,
				isProcessingFriendshipAction: false,
			};
		case UNFRIEND_SEND_SUCCESS:
			return {
				...state,
				isProcessingFriendshipAction: false,
				friends: [...state.friends.slice(0, action.payload.index),
							...state.friends.slice(action.payload.index + 1)]
			};
		case LOGIN_SUCCESS:
		case USER_LOAD_SUCCESS:
			return {
				...state,
				pendingRequestsFrom: action.payload.relationships.requests_from,
				pendingRequestsTo: action.payload.relationships.requests_to,
				friends: action.payload.relationships.friends
			}
		case LOGOUT_FAIL:
		case LOGOUT_SUCCESS:
		case USER_LOAD_FAIL:
			return {
				...state,
				friends: null,
				pendingRequestsFrom: null,
				pendingRequestsTo: null
			}
		default:
			return state;
	}
};