import _ from 'lodash';
import axios from '../common/axios';
import {
    AUTH_NO_TOKEN,
    INVITATION_SUCCESS,
    INVITATION_FAIL,
    REGISTERING_USER,
    SENDING_INVITATION,
    USER_REGISTRATION_SUCCESS,
    USER_REGISTRATION_FAIL,
    UPDATE_INVITE_DATA_SUCCESS,
    UPDATE_INVITE_DATA_FAIL
} from '../common/types';
import { getCsrfHeaders } from './auth';
import { httpCodes } from '../common/network/http';

export const sendInvitation = (values) => {
    return async(dispatch, getState) => {
        // This action requires authentication. Get the token,
        // and bail out if we can't find it.
        const headers = getCsrfHeaders(getState);
        if(_.isEmpty(headers)) {
            dispatch({ type: AUTH_NO_TOKEN });
        }
        else {
            try {
                // Send the request to the server
                dispatch({ type: SENDING_INVITATION });
                const payload = JSON.stringify(values, ['fname', 'lname', 'email']);
                const res = await axios.post('/invitations/send/', payload, headers);

                // If we succeed, there are a couple options:
                // 1. Success and the new invite and manager come back:
                //      Update the data, no need to warn the user
                // 2. Success, but the new invite and/or manager do NOT come back:
                //      Update the data, warn the user about inconsistent state
                if(res.data.success) {
                    dispatch({type: INVITATION_SUCCESS});
                    if(res.data.invitation && res.data.manager) {
                        console.log('Good update.');
                        dispatch({
                            type: UPDATE_INVITE_DATA_SUCCESS,
                            payload: res.data
                        });
                    }
                    else {
                        console.log('Bad update');
                        dispatch({type: UPDATE_INVITE_DATA_FAIL});
                    }
                    console.log('Success return.');
                    return {
                        success: true
                    };
                }
                else {
                    dispatch({ type: INVITATION_FAIL });
                    console.log('Complete failure.');
                    return {
                        success: false,
                        errors: {non_field_errors: res.data.msg}
                    };
                }
            }
            catch(e) {
                dispatch({ type: INVITATION_FAIL });

                if(e.response) {
                    const errors = {};
                    let httpCode = httpCodes[e.response.status] || httpCodes['default'];
                    let http_msg = httpCode.type + ' ' + httpCode.code + ': ' + httpCode.msg;
                    if(e.response.data) {
                        const data = e.response.data;
                        
                        if(data.email) {
                            errors.email = data.email;
                        }
                        if(data.fname) {
                            errors.fname = data.fname;
                        }
                        if(data.lname) {
                            errors.lname = data.lname;
                        }
                    }

                    let err_str = e.response.msg
                        ? e.response.msg
                        : http_msg;

                    errors.non_field_errors = err_str;
                    return {
                        success: false,
                        errors: errors
                    };
                }
            }
        }
    };
};

export const rescindInvitation = (invitation) => {
    return async(dispatch, getState) => {
        // This action requires authentication. Get the token,
        // and bail out if we can't find it.
        const headers = getCsrfHeaders(getState);
        if(_.isEmpty(headers)) {
            dispatch({ type: AUTH_NO_TOKEN });
        }
        const payload = JSON.stringify(invitation);
        console.log(payload);
        return {};
    };
};

export const acceptInvitation = (id, values) => {
    return async(dispatch, getState) => {
        const headers = getCsrfHeaders(getState);
        dispatch({ type: REGISTERING_USER });
        try {
            const payload = JSON.stringify({
                user: values.user,
                profile: values.profile
            });
            console.log(payload);
            const res = await axios.post(`/invitations/accept/${id}/`, payload, headers);
            if(res.data.success && res.data.user && res.data.authtoken) {
                dispatch({
                    type: USER_REGISTRATION_SUCCESS,
                    payload: res.data
                });
                return {success: true};
            }
            else {
                dispatch({type: USER_REGISTRATION_FAIL});
                const errors = res.data.errors || {};
                if(res.data.msg) {
                    errors.non_field_errors = res.data.msg;
                }        
                return {
                    success: false,
                    errors: errors
                };
            }
        }
        catch(e) {
            dispatch({type: USER_REGISTRATION_FAIL});
            if(e.response) {
                const errors = {};
                const httpCode = httpCodes[e.response.status] || httpCodes['999'];
                const http_msg = `${httpCode.type} ${httpCode.code}: ${httpCode.msg}`;
                if(httpCode.type === 'Error') {
                    errors.non_field_errors = [http_msg];
                };
                if(e.response.data) {
                    const data = e.response.data;
                    const errors = data.errors || {};
                    if(data.msg) {
                        errors.non_field_errors
                            ? errors.non_field_errors.push(data.msg)
                            : errors.non_field_errors = [data.msg]
                        ;
                    }
                    if(data.non_field_errors) {
                        errors.non_field_errors
                            ? errors.non_field_errors.push(data.non_field_errors)
                            : errors.non_field_errors = [data.non_field_errors]
                        ;
                    }
                }
                return {
                    success: false,
                    errors: errors
                };
            }
           return {
               success: false,
               errors: {non_field_errors: 'Either you are offline, or fz is.'}
           };
        }
    };
};

export const extendInvitation = (inviteId) => {
};

export const verifyInvitation = (inviteId) => {
    return async (dispatch, getState) => {
        const headers = getCsrfHeaders(getState);
        try {
            const res = await axios.get(`/invitations/verify/${inviteId}`, headers);
            return({
                success: res.data.success,
                invitation: res.data.invitation
            });
            
        }
        catch(e) {
            return({success: false});
        }
    };
};

export const deleteInvitation = (inviteId) => {
    return async(dispatch, getState) => {
        const headers = getCsrfHeaders(getState);
        try {
            const res = await axios.delete(`/invitations/delete/${inviteId}/`, headers);
            if(res.data.success) {
                return({success: res.data.success});
            }
            else {
                return {
                    success: false,
                    errors: res.data.msg
                };
            }
        }
        catch(e) {
            if(e.response) {
                let httpCode = httpCodes[e.response.status] || httpCodes['default'];
                let httpMsg = `${httpCode.type} ${httpCode.code}: ${httpCode.msg}`;
                
                return {
                    success: false,
                    errors: httpMsg
                };
            }
        }
    };
};
