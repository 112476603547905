/* Handle the display of login notifications that will appear on the main page.
 * These will (in the future) also be visible in the App Bar, but now they're
 * going to be here, and only here. */
import { Box } from 'grommet';
import { InfoAlert } from '../common/cmp_alerts';
import { useSelector } from 'react-redux';

// An area for displaying notifications that the user needs to see. We will expand this to
// include additional types of notifications as time goes on. Notifications use the "Alert"
// component, not the "Notification" component. Sorry. Backend naming conventions and component 
//purpose diverged a bit.
const HomepageNotificationArea = () => {
	const basicNotifications = useSelector((state) => state.userNotifications.basicNotifications);

	return (
	<>
	{basicNotifications.length > 0 && (
	<Box
		direction='column'
		fill='horizontal'
		gap='small'
		margin={{bottom: 'large'}}>
			
		{basicNotifications.map((notification, index) => {
			return (
				<InfoAlert
					key={`basic-notification-${index}`}
					msg={notification.text} />
			);
		})}
	</Box>
	)}
	</>
	);
};

export default HomepageNotificationArea;