import {
    Box,
    Button,
    Form,
    FormField,
    Text,
    TextArea
} from 'grommet'
import {
    ErrorNotification,
    WarningNotification,
    SuccessNotification,
} from '../common/cmp_notifications';
import { ActionableModal } from '../common/cmp_modals';
import { ErrorCard, StandardCard } from '../common/cmp_cards';
import { buildDateString } from '../common/helpers';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editPost, deletePost } from './actions';

const SelfPosts = (props) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDeleteSuccessNotification, setShowDeleteSuccessNotification] = useState(false);
    const [showDeleteFailNotification, setShowDeleteFailNotification] = useState(false);
    const [post, setPost] = useState({});
    const [postIndex, setPostIndex] = useState(-1);
    const recentPosts = useSelector((state) => state.userPosts.recent);
    const dispatch = useDispatch();

    const handleModalShow = (index) => {
        setPost(recentPosts[index]);
        setPostIndex(index);
        setShowDeleteModal(true);
    };

    const handleDeleteConfirmation = () => {
        setIsDeleting(true);
        dispatch(deletePost(postIndex, post.apid), [dispatch]).then((res) => {
            setShowDeleteSuccessNotification(false);
            setShowDeleteFailNotification(false);
            if(res.success) {
                setShowDeleteSuccessNotification(true);
            }
            else {
                setShowDeleteFailNotification(true);
            }
        });
        setShowDeleteModal(false);
        setIsDeleting(false);
    };

    const handleDeleteCancellation = () => {
        setPost({});
        setPostIndex(-1);
        setShowDeleteModal(false);
    };
    
    return (
    <Box
        direction='column'
        align='center'
        gap='medium'
        fill='horizontal'>

        <Text weight='bold'>Your Posts</Text>
        {recentPosts.map((post, index) => (
            <SelfPostCard
                key={index}
                index={index}
                post={post}
                clickHandler={handleModalShow} />
        ))}
        
        <ActionableModal
            head='Delete Post?'
            body='This action is permanent. If you delete this post, it will be gone forever.'
            actions={
                <Button
                    primary
                    color='status-critical'
                    label='Delete'
                    icon={isDeleting 
                        ? <i className='fas fa-spin fa-spinner' />
                        : <i className='fas fa-trash-alt' />}
                    onClick={handleDeleteConfirmation} />
            }
            show={showDeleteModal}
            setShow={handleDeleteCancellation} />

        <WarningNotification
            show={showDeleteSuccessNotification}
            setShow={setShowDeleteSuccessNotification}
            msg='Post deleted successfully'
            icon={<i className='fas fa-check-circle' />}
            timeout />

        <ErrorNotification
            show={showDeleteFailNotification}
            setShow={setShowDeleteFailNotification}
            msg='Error deleting post'
            timeout />
    </Box>
    );
};

const SelfPostCard = ({index, post, clickHandler}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editSuccess, setEditSuccess] = useState(false);
    const [editError, setEditError] = useState(false);

    const handleDeleteButtonClick = (e) => {
        clickHandler(index);
    };

    const handleEditButtonClick = (e) => {
        setIsEditing(true);

    }

    const handleEditCancelButtonClick = (e) => {
        setIsEditing(false);
    }

    return (
        <>
        {isEditing ? (
            <SelfPostCardEditMode
                key={`${index}-edit-card`}
                index={index}
                post={post}
                cancelHandler={handleEditCancelButtonClick}
                showSuccess={editSuccess}
                setShowSuccess={setEditSuccess}
                showError={editError}
                setShowError={setEditError} />
        ) : (
            <SelfPostCardViewMode
                key={`${index}-view-card`}
                index={index}
                post={post}
                editHandler={handleEditButtonClick}
                deleteHandler={handleDeleteButtonClick} />
        )}

        <SuccessNotification
            show={editSuccess}
            setShow={setEditSuccess}
            msg='Post edited successfully'
            timeout />

        <ErrorNotification
            show={editError}
            setShow={setEditError}
            msg='Error editing post.'
            timeout />
        </>
    );    
};

const SelfPostCardViewMode = ({index, post, editHandler, deleteHandler}) => {
    return (
    <StandardCard
        head={<SelfPostCardHeader created={buildDateString(post.created)} edited={buildDateString(post.edited)} />}
        body={
            <Box
                fill='horizontal'
                padding='small'>

                {post.content.split('\n').map((p, pidx) => {
                    return(<Text key={`${index}-content-text-${pidx}`}>{p}</Text>);
                })}
                    
            </Box>
        }
        foot={
            <Box
                direction='row-responsive'
                fill='horizontal'
                justify='end'
                gap='medium'>

                <Button
                    color='brand'
                    secondary
                    onClick={editHandler}
                    icon={<i className='fas fa-edit' />}
                    label='Edit' />
            
                <Button
                    color='status-critical'
                    secondary
                    onClick={deleteHandler}
                    icon={<i className='fas fa-trash-alt' />}
                    label='Delete' />
            </Box>
        }
        />
    );
};

const SelfPostCardEditMode = ({index, post, cancelHandler, showSuccess, setShowSuccess, showError, setShowError}) => {
    const [formValue, setFormValue] = useState({editedpost: post.content});
    const [formErrors, setFormErrors] = useState({});
    const [editProcessing, setEditProcessing] = useState(false);
    const dispatch = useDispatch();

    const setLimitedValue = useCallback(content => {
        setFormValue({
            ...content,
            editedpost: content.editedpost.slice(0, 256),
        });
    },
    [setFormValue]);

    const clearForm = () => {
        setFormValue({editedPost: post.content});
    }

    const handleEditSaveButtonClick = (e) => {
        e.preventDefault();
        setEditProcessing(true);
        dispatch(editPost(index, post.apid, formValue.editedpost), [dispatch]).then((res) => {
            setShowSuccess(false);
            setShowError(false);
            if(res.success) {
                setShowSuccess(true);
                setEditProcessing(false);
                cancelHandler();
            }
            else {
                setShowError(true);
                setEditProcessing(false);
                setFormErrors(res.errors);
            }
        })
    };

    return (
        <Form
            value={formValue}
            errors={formErrors}
            style={{width: '100%'}}
            onChange={setLimitedValue}
            onReset={clearForm}
            onSubmit={handleEditSaveButtonClick}>

            <StandardCard
                head={<SelfPostCardHeader created={buildDateString(post.created)} edited={buildDateString(post.edited)} />}
                body={
                    <Box
                        fill='horizontal'
                        padding='xlarge'>

                        {formErrors.non_field_errors && (
                            <ErrorCard 
                                body={formErrors.non_field_errors} />
                        )}

                        <FormField
                            a11yTitle={`Edit post ${post.id} content.`}
                            name='editedpost'
                            rows={Math.max(post.content.split('\n').length, 3)}
                            style={{width: '100%'}}
                            resize={false}
                            info={`${formValue.editedpost.length}/256`}

                            as={TextArea} />
                    </Box>
                } 
                foot={
                    <Box
                        direction='row-responsive'
                        fill='horizontal'
                        justify='end'
                        gap='medium'>
                        
                        <Button
                            color='status-warning'
                            secondary
                            onClick={cancelHandler}
                            disabled={editProcessing}
                            icon={<i className='fas fa-times' />}
                            label='Cancel' />

                        <Button
                            color='status-ok'
                            primary
                            type='submit'
                            disabled={(formValue.editedpost === post.content) || editProcessing}
                            onClick={() => {}}
                            icon={editProcessing
                                ? <i className='fas fa-spin fa-spinner' />
                                : <i className='fas fa-edit' />
                            }
                            label='Save' />
                    </Box>
                } />
        </Form>
    );
};

const SelfPostCardHeader = ({created, edited}) => {
    return (
        <Box
            direction='column'
            fill='horizontal'>
            
            {`On ${buildDateString(created)}:`}
            {edited && (
                <Text size='small' color='text-xweak'>
                    {`(Edited on ${buildDateString(edited)})`}
                </Text>
            )}        
        </Box>
    );
}

export {SelfPosts};
