import { app_reducer } from '../reducers/app';
import { auth_reducer } from '../auth/reducers';
import { combineReducers } from 'redux';
import { invitation_reducer } from '../reducers/invitations';
import { notificationsReducer } from '../notifications/reducers';
import { friendPostsReducer, userPostsReducer } from '../posts/reducers';
import { relationshipReducer } from '../relationships/reducers';
import { userReducer } from '../base/reducers';


export default combineReducers({
	app: app_reducer,
    auth: auth_reducer,
    user: userReducer,
    userInvitations: invitation_reducer,
    userPosts: userPostsReducer,
    userRelationships: relationshipReducer,
    userNotifications: notificationsReducer,
    friendPosts: friendPostsReducer
})
