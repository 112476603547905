import {
    Box,
    Button,
    Form,
    FormField,
    Heading,
    Paragraph,
    Text,
    TextArea,
    TextInput
} from 'grommet';
import {
    StandardCard,
    ErrorCard,
} from '../common/cmp_cards';
import {
    FormFieldError
} from '../common/components/errors';
import { ActionableModal } from '../common/cmp_modals';
import { FullScreenLoadingSpinner } from '../common/components/loading';
import { 
    acceptInvitation,
    deleteInvitation, 
    verifyInvitation,
} from '../actions/invitations';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Route, useHistory, useParams } from 'react-router-dom';

const InvitationResponse = () => {
    return (
    <Box
        direction='column'
        align='center'
        gap='medium'
        pad='small'
        fill='horizontal'>

        <Heading margin='small' size='small'>
            FZ Registration
        </Heading>
        <Route exact path='/register' children={<NoInvitationProvidedCard />} />
        <Route path='/register/:id' children={<MainInvitationPane />} />
        
    </Box>
    );
};

const NoInvitationProvidedCard = () => (
    <StandardCard body='This site is currently invitation-only and there is no way to circumvent this requirement for now. Please contact someone who has an account to request an invitation.' />
);

const MainInvitationPane = () => {
    const { id } = useParams();
    const [component, setComponent] = useState(
        <FullScreenLoadingSpinner msg='Verifying Ivitation...' />
    );

    // Verify the invitation when the application is loaded.
    // Display an error if the server returns anything other
    // than success.
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(verifyInvitation(id), [dispatch]).then((res) => {
            if(res.success) {
                setComponent(<InvitationFoundCard invitation={res.invitation} />);
            }
            else {
                setComponent(<ErrorCard
                    title='Error Loading Invitation'
                    msg='Your invitation could not be loaded. Please contact the person who sent it to you for assistance.' />);
            }
        });
    }, [dispatch, id]);

    return (
    <>
    {component}
    </>
    );
};

const InvitationFoundCard = ({invitation}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeclining, setIsDeclining] = useState(false);
    const [hasDeclined, setHasDeclined] = useState(false);

    // Display the decline confirmation if the invitation has been declined,
    // or show the invitation form otherwise.
    return (
    <>
    {hasDeclined ? (
        <StandardCard
            body="Invitation declined. We're sorry you chose not to join." />
    ) : (
        <StandardCard
            body={
            <Box
                fill='horizontal'
                direction='column'
                gap='small'>
                           
                <Paragraph fill>
                You have been invited to the not-quite-revolutionary <Text weight='bold'>fz</Text> social network. Please fill out the form below to register, or click the red "decline" button at the bottom of the page to (obviously) decline the invitation.
                </Paragraph>
            
                <Box margin={{horizontal: 'medium'}}>
                    <InvitationForm
                        invitation={invitation}
                        isSubmitting={isSubmitting}
                        isDeclining={isDeclining}
                        setIsSubmitting={setIsSubmitting} />
                </Box>
            </Box>
            } 
            foot={<DeclineInvitationFooter
                invitation={invitation}
                isSubmitting={isSubmitting}
                isDeclining={isDeclining}
                setIsDeclining={setIsDeclining}
                setHasDeclined={setHasDeclined} />}
        />
    )}
    </>
    );
};

const InvitationForm = ({invitation, isSubmitting, isDeclining, setIsSubmitting}) => {
    const [values, setValues] = useState({
        username: '',
        password: '',
        confirm_password: '',
        first_name: '',
        last_name: '', 
        display_name: '',
        bio: ''
    });

    const [formErrors, setFormErrors] = useState({});
    const history = useHistory();
    const dispatch = useDispatch();

    // Pre-populate the invitation form
    useEffect(() => {
        setValues({
            username: invitation.email,
            password: '',
            confirm_password: '',
            first_name: invitation.fname,
            last_name: invitation.lname,
            display_name: `${invitation.fname} ${invitation.lname}`,
            bio: ''
        });
    }, [invitation.email, invitation.fname, invitation.lname]);

    // Handle the value update for the form, needed in this case
    // because the bio field length is restricted.
    const handleValueUpdate = useCallback(
        intermediateValues => {
            setValues({
                ...intermediateValues,
                bio: intermediateValues.bio.slice(0, 256)
            });
        },
        [setValues]
    );
    
    // Handle submission
    const handleSubmit = () => {
        // Handle unequal passwords by returning an error
        // and not proceeding.
        setIsSubmitting(true);
        setFormErrors({});
        if(values.password !== values.confirm_password) {
            setFormErrors({
                password: 'Passwords must match',
                confirm_password: 'Passwords must match'
            });
        }
        else {
            const pack = {
                user: {
                    username: values.username,
                    first_name: values.first_name,
                    last_name: values.last_name,
                    password: values.password
                },
                profile: {
                    display_name: values.display_name,
                    bio: values.bio
                }
            };

            dispatch(acceptInvitation(invitation.id, pack), [dispatch]).then((res) => {
                console.log(res);
                if(res.success) {
                    history.push('/');
                }
                else {
                    const tmp = {};
                    for(const [i, v] of Object.entries(res.errors)) {
                        tmp[i] = Array.isArray(v)
                            ? <FormFieldError field={v} />
                            : v
                    }
                    console.log(tmp);
                    setFormErrors(tmp);
                }
                setIsSubmitting(false);
            });
        }
    };

    return (
    <Form
        value={values}
        errors={formErrors}
        onChange={(newValues) => handleValueUpdate(newValues)}
        onReset={() => {}}
        onSubmit={handleSubmit}>

        {formErrors.non_field_errors ? (
            <ErrorCard title='Error Registering User' msg={formErrors.non_field_errors} />
        ) : null}
            
        <FormField
            a11yTitle='Email address: this will be your username'
            name='username'
            label={<Text color='status-critical-text'>* Email Address</Text>}
            info='This will be your username'
            required={true}
            as={TextInput} />

        <FormField
            a11yTitle='Password'
            name='password'
            label={<Text color='status-critical-text'>* Password</Text>}
            required={true}>

            <TextInput
                id='password'
                name='password'
                type='password'
                autocomplete='new-password'
                placeholder='Make it strong, please.'
                value={values.password} />
        </FormField>
            
        <FormField
            a11yTitle='Confirm Password'
            name='confirm_password'
            label={<Text color='status-critical-text'>* Confirm Password</Text>}
            required={true}>

            <TextInput
                id='confirm_password'
                name='confirm_password'
                type='password'
                autocomplete='new-password'
                value={values.confirm_password} />
        </FormField>

        <FormField
            a11yTitle='First Name'
            name='first_name'
            label={<Text color='status-critical-text'>* First Name</Text>}
            required={true}
            as={TextInput} />

        <FormField
            a11yTitle='Last Name'
            name='last_name'
            label={<Text color='status-critical-text'>* Last Name</Text>}
            required={true}
            as={TextInput} />

        <FormField
            a11yTitle='Display Name'
            name='display_name'
            label={<Text color='status-critical-text'>* Display Name</Text>}
            required={true}
            as={TextInput} />

        <FormField
            a11yTitle='A little bit about yourself'
            rows='3'
            name='bio'
            label='Bio'
            resize={false}
            info={`${values.bio.length}/256`}
            margin={{bottom: 'medium'}}
            placeholder='Write a little bit about yourself here.'
            as={TextArea} />

        <Box align='center' margin='small'>
            <Button
                primary
                disabled={(isSubmitting || isDeclining) ? true : false}
                type='submit'
                icon={isSubmitting
                    ? <i className='fas fa-spin fa-spinner' />
                    : <i className='fas fa-user-plus' />
                }
                label='Register'
                color='brand' />
        </Box>
    </Form>
    );
};

const DeclineInvitationFooter = ({
    invitation,
    isSubmitting,
    isDeclining,
    setIsDeclining,
    setHasDeclined
}) => {
    const [showModal, setShowModal] = useState(false);
    const [declineErrors, setDeclineErrors] = useState('');

    // Show the modal
    const handleShowModal = (e) => {
        e.preventDefault();
        setShowModal(true);
    };

    // Handle the user confirming invitation decline
    const dispatch = useDispatch();
    const handleDeclineConfirm = useCallback(e => {
        e.preventDefault();
        setIsDeclining(true);
        setShowModal(false);
        dispatch(deleteInvitation(invitation.id), [dispatch]).then((res) => {
            if(res.success) {
                setIsDeclining(false);
                setHasDeclined(true);
            }
            else {
                setIsDeclining(false);
                setDeclineErrors(res.errors);
            }
        });
    }, [setIsDeclining, setHasDeclined, dispatch, invitation.id]);

    return (
    <>
    <Box fill='horizontal' align='center' margin='small' gap='medium'>
        {declineErrors && (
            <ErrorCard
                title='Error Deleting Invitation'
                msg={declineErrors} />
        )}
        
        <Button
            secondary
            disabled={(isSubmitting || isDeclining) ? true : false}
            icon={isDeclining 
                ? <i className='fas fa-spin fa-spinner' />
                : <i className='fas fa-trash-alt' />
            }
            label='Decline'
            color='status-critical' 
            onClick={handleShowModal} />
    </Box>

    <ActionableModal
        head='Decline Invitation?'
        body='Are you sure you want to decline this invitation? This action cannot be reversed. If you choose to decline, you will need to receive a new invitation to join.'
        actions={
            <Button
                primary
                color='status-critical'
                label='Decline' 
                icon={<i className='fas fa-trash-alt' />} 
                onClick={handleDeclineConfirm} />
        }
        show={showModal}
        setShow={setShowModal} />
    </>
    );
};

export default InvitationResponse;
