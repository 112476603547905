import {
    Box,
    Button,
    Form,
    FormField,
    Heading,
    Text,
    TextInput
} from 'grommet';
import { StandardCard, WarningCard } from '../common/cmp_cards';
import { ActionableModal } from '../common/cmp_modals';
import { ErrorField } from '../common/components/form_fields';
import { SuccessNotification } from '../common/cmp_notifications';
import {
    rescindInvitation,
    sendInvitation
} from '../actions/invitations';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

const InvitationManager = (props) => {
    return (
    <Box
        direction='column'
        align='center'
        gap='small'
        pad='small'
        fill='horizontal'>

        <Heading margin='small' size='small'>
            Invitation Manager
        </Heading>

        {process.env.REACT_APP_FZ_ENV === 'FZDEV' && (
            <WarningCard
                head='Dev Environment and Email'
                body='Email will not work in the development environment. Create invitations through the dev shell when in the dev environment. Use the test environment to verify email functionality.' />
        )}
        
        <Box
            direction='column'
            align='center'
            gap='large'
            fill='horizontal'>
            
            <PendingInvitationsArea />

            <InvitationFormArea />
        </Box>
    </Box>
    ); 
};

const PendingInvitationsArea = () => {
    const [targetInvitation, setTargetInvitation] = useState({});
    const [showRescindModal, setShowRescindModal] = useState(false);
    const userInvitations = useSelector((state) => state.userInvitations.pending);
    const dispatch = useDispatch();

    const handleRescindShow = (invitation) => {
        setTargetInvitation(invitation);
        setShowRescindModal(true);
    }

    const handleRescindCancellation = (invitation) => {
        setTargetInvitation({});
        setShowRescindModal(false);
    };

    const handleRescindConfirmation = () => {
        if(targetInvitation) {
            dispatch(rescindInvitation(targetInvitation), [dispatch]).then((res) => {
            });
        }
        setShowRescindModal(false);
    };
    
    return (
    <>
    {userInvitations && userInvitations.length > 0 ? (
        <Box
            fill='horizontal'
            gap='small'>
            
            {userInvitations.map((invitation, index) => (
                <PendingInvitationCard
                    key={index}
                    invitation={invitation}
                    index={index} 
                    rescindHandler={handleRescindShow} />
            ))}

        <ActionableModal
            head='Rescind Invitation?'
            body={`Are you sure you want to rescind this invitation to ${targetInvitation.fname} ${targetInvitation.lname}? It will be credited back to you, but the invitee will no longer be able to join.`}
            actions={
                <Button
                    primary
                    color='status-critical'
                    label='Rescind'
                    icon={<i className='fas fa-user-slash' />}
                    onClick={handleRescindConfirmation} />
            }
            show={showRescindModal}
            setShow={handleRescindCancellation} />
        </Box>
    ) : (
        <NoPendingInvitationsCard />
    )}
    </>
    );
};

const PendingInvitationCard = ({invitation, index, rescindHandler}) => {
    const localRescindHandler = () => {
        rescindHandler(invitation);
    };

    return (
    <StandardCard
        head={`To ${invitation.fname} ${invitation.lname}`}
        body={
            <Box
                fill='horizontal'
                justify='start'>
                
                <Text>{invitation.email}</Text>
                <Text>{`Sent: ${invitation.created}`}</Text>
                <Text>{`Expires: ${invitation.expiration}`}</Text>
            </Box>
        }
        foot={
            <Box
                direction='row'
                fill='horizontal'
                justify='end'
                gap='medium'>
                <Button
                    secondary
                    color='brand'
                    label='Extend'
                    icon={<i className='fas fa-calendar-week' />} />
                <Button
                    secondary
                    color='status-critical'
                    label='Rescind'
                    icon={<i className='fas fa-user-slash' />} 
                    onClick={localRescindHandler}
                />
            </Box>
        } />
    );
};

const NoPendingInvitationsCard = () => {
    return (
    <StandardCard
        body='No invitations you have sent are currently pending.'
    />
    );
};

const InvitationFormArea = () => {
    const userInvitesRemaining = useSelector((state) => state.userInvitations.manager.invites_remaining);
    return (
    <>
    { userInvitesRemaining ? (
        <InvitationFormCard />
    ) : (
        <NoInvitationsRemainingCard />
    )}
    </>
    );
};

const InvitationFormCard = () => {
    const manager = useSelector((state) => state.userInvitations.manager);
    return (
        <StandardCard
            head='Send Invitation'
            subHead={manager.infinite_inviter
                ? ('Unlimited Invitations Remaining')
                : (`${manager.invites_remaining} Remaining`)
            }
            body={
                <InvitationForm />
            }
        />
    );
};

const InvitationForm = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [value, setValue] = useState({
        fname: '',
        lname: '',
        email: '',
        email_confirm: ''
    });
    const [formErrors, setFormErrors] = useState({});
    const [showSuccess, setShowSuccess] = useState(false);
    
    const dispatch = useDispatch();
    const handleSubmission = (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        if(value.email === value.email_confirm) {
            setFormErrors({});
            dispatch(sendInvitation(value), [dispatch]).then((res) => {
                if(!res.success) {
                    setFormErrors(res.errors);
                }
                else {
                    setShowSuccess(true);
                }
                setIsSubmitting(false);
            });
        }
        else {
            setFormErrors({
                email: 'Email addresses must match',
                email_confirm: 'Email addresses must match'
            });
            setIsSubmitting(false);
        }
    };

    return (
    <Form
        value={value}
        onChange={newValue => setValue(newValue)}
        onReset={() => {}}
        errors={formErrors}
        onSubmit={handleSubmission}>

        <ErrorField msg={formErrors.non_field_errors}
            show={formErrors.non_field_errors ? true : false}
            setShow={() => {}} />

        <FormField name='fname'
            htmlfor='email-id'
            label='First Name'
            margin={{bottom: 'medium'}}
            required={true}
            as={TextInput} />

        <FormField name='lname'
            htmlfor='fname-id'
            label='Last Name'
            margin={{bottom: 'medium'}}
            required={true}
            as={TextInput} />

        <FormField name='email'
            htmlfor='email-id'
            label='Email Address'
            margin={{bottom: 'medium'}}
            required={true}
            as={TextInput} />
        
        <FormField name='email_confirm'
            htmlfor='email_confirm-id'
            label='Confirm Email'
            margin={{bottom: 'medium'}}
            required={true}
            as={TextInput} />

        <Box align='center' margin='small'>
        <Button
            type='submit'
            icon={isSubmitting
                ? <i className='fas fa-spin fa-spinner' />
                : <i className='fas fa-paper-plane' />}
            label='Send'
            plain={false}
            primary
            disabled={isSubmitting} />
        </Box>
        
        <SuccessNotification
            show={showSuccess}
            setShow={setShowSuccess}
            msg='Invitation created successfully!' />
    </Form>
    );
};

const NoInvitationsRemainingCard = ({props}) => {
    return (
    <StandardCard
        body='You have no invitations remaining.'
    />
    );
};

export default InvitationManager;
