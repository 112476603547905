/* Alerts contain nuggets of information. They are different from the basic card types because they
 * are dismissable. They are different than notifications because they appear by default. */
/* Sorry for the confusion distinguishing between Notifications and Alerts, but "BasicTextNotification"
 * in the BE is a data structure, and alert is a visual element. Neither is exclusive of the other. */
import {
	Box,
	Button,
	Text
} from 'grommet';
import {
	StandardCard
} from './cmp_cards';
import { useState } from 'react';

export const InfoAlert = ({msg, dismissable=true, ...props}) => {
	return (
		<StandardAlert
			msg={msg}
			icon={props.icon || <i className='fas fa-info-circle' />}
			dismissable={dismissable}
			borderColor='status-info'
			backgroundColor='status-info-bg'
			textColor='status-info-text' />
	);
};

export const StandardAlert = ({msg, dismissable=true, ...props}) => {
	const [show, setShow] = useState(true);
	const [borderColor] = useState(props.borderColor || 'brand');
	const [backgroundColor] = useState(props.backgroundColor || 'background-front');
	const [textColor] = useState(props.textColor || 'text');
	const [animation, setAnimation] = useState({});

	const handleCloseButtonClick = () => {
		setAnimation({type: 'fadeOut', duration: '250'});
		const timeout = setTimeout(() => {
			setShow(false);
		}, 500);
		return () => clearTimeout(timeout);
	};

	return (
		<>
		{show && (
			<Box
				fill='horizontal'
				animation={animation}>
			<StandardCard
				borderColor={borderColor}
				baseBackgroundColor={backgroundColor}
				baseTextColor={textColor}
				body={
					<Box 
						direction='row'
						fill='horizontal'
						align='center'
						gap='small'
						justify='center'>

						{props.icon && (
							<Text color={textColor} size='xlarge' weight='bold'>
								{props.icon}
							</Text>
						)}

						<Box fill='horizontal'><Text color={textColor}>{msg}</Text></Box>
						
						{dismissable && (
							<Button
								plain
								color={textColor}
								icon={<Text size='xlarge' weight='bold'><i className='fas fa-times-circle' /></Text>}
								onClick={handleCloseButtonClick} />
						)}
							
					</Box>
				} />
			</Box>
		)}
		</>
	);
}