import axios from '../common/axios';
import { httpCodes, httpErrorHelper } from '../common/network/http';
import {
    FAVORITE_POST,
    FAVORITE_POST_FAIL,
    FAVORITE_POST_SUCCESS,
    MY_POSTS_GET,
    MY_POSTS_GET_FAIL,
    MY_POSTS_GET_SUCCESS,
    POST_DELETE,
    POST_DELETE_FAIL,
    POST_DELETE_SUCCESS,
    POST_SEND,
    POST_SEND_FAIL,
    POST_SEND_SUCCESS,
    POST_UPDATE,
    POST_UPDATE_FAIL,
    POST_UPDATE_SUCCESS
} from '../common/types';

export const getSelfPosts = () => {
    return async(dispatch, getState) => {
        dispatch({ type: MY_POSTS_GET });
        try {
            const res = await axios.get('/posts/get/mine/');
            if(res.success) {
                dispatch({
                    type: MY_POSTS_GET_SUCCESS,
                    payload: { recentPosts: res.data.posts }
                });
                return { success: true };
            }
            else {
                dispatch({ type: MY_POSTS_GET_FAIL });
                return { success: false };
            }
        }
        catch(e) {
            dispatch({ type: MY_POSTS_GET_FAIL });
            return { success: false };
        }
    };
};

export const getPosts = (userId) => {
    return async(dispatch, getState) => {
        dispatch({ type: MY_POSTS_GET });
        try {
            const url = `/posts/get/${userId}/`;
            const res = await axios.get(url);
            if(res.success) {
                dispatch({
                    type: MY_POSTS_GET_SUCCESS,
                    payload: { recentPosts: res.data.posts }
                });
                return {
                    success: true,
                    recentPosts: res.data.posts
                };
            }
        }
        catch(e) {
            dispatch({ type: MY_POSTS_GET_FAIL });
            return { success: false };
        }
    }
};

export const quickPost = (content) => {
    return async (dispatch, getState) => {
        dispatch({ type: POST_SEND });
        try {
            const payload = JSON.stringify({content});
            const res = await axios.post('/posts/quick/', payload);
            dispatch({
                type: POST_SEND_SUCCESS,
                payload: { post: res.data.post }
            });
            return {
                success: true,
                msg: 'Post created',
                post: res.data.post
            };
        }
        catch(e) {
            dispatch({ type: POST_SEND_FAIL });
            if(e.response) {
                let httpCode = httpCodes[e.response.status] || httpCodes['default'];
                let http_msg = httpCode.type + ' ' + httpCode.code + ': ' + httpCode.msg;
                let err_str = e.response.msg
                    ? e.response.msg
                    : http_msg;
                return {
                    success: false,
                    msg: err_str
                };
            }
        }
    };
};

export const editPost = (arrayIdx, id, content) => {
    return async(dispatch, getState) => {
        dispatch({ type: POST_UPDATE });
        try {
            const payload = JSON.stringify({ content });
            const res = await axios.patch(`/posts/edit/${id}/`, payload);
            if(res.data.success) {
                dispatch({
                    type: POST_UPDATE_SUCCESS,
                    payload: { 
                        index: arrayIdx,
                        post: res.data.post
                    }
                });
                return { success: true };
            }
            else {
                dispatch({ type: POST_UPDATE_FAIL });
                return {
                    success: false,
                    errors: httpErrorHelper(res.status, res)
                };
            }
        }
        catch(e) {
            dispatch({type: POST_UPDATE_FAIL });
            return {
                success: false,
                errors: httpErrorHelper(e.response.status, e.response.data)
            }
        }
    };
};

export const deletePost = (arrayIdx, id) => {
    return async(dispatch, getState) => {
        dispatch({ type: POST_DELETE });
        try {
            const res = await axios.delete(`/posts/delete/${id}/`);
            if(res.data.success) {
                dispatch({
                    type: POST_DELETE_SUCCESS,
                    payload: { index: arrayIdx }
                });
                return {
                    success: true,
                    idx: arrayIdx,
                    id: id
                };
            }
            else {
                dispatch({ type: POST_DELETE_FAIL })
                return { success: false };
            }
        }
        catch(e) {
            dispatch({ type: POST_DELETE_FAIL });
            return { success: false };
        }
    };
};

export const favoritePost = (arrayIdx, id, mode) => {
    return async(dispatch, getState) => {
        dispatch({ type: FAVORITE_POST });
        try {
            await axios.patch(`/posts/favorite/${id}/`);
            dispatch({ 
                type: FAVORITE_POST_SUCCESS,
                payload: {
                    index: arrayIdx
                }
            });
            return ({ success: true });
        }
        catch(e) {
            dispatch({ type: FAVORITE_POST_FAIL });
            return ({ success: false });
        }
    };
};