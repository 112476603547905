import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {FullScreenLoadingSpinner} from './components/loading.js';

const AuthenticatedRoute = ({component: Component, ...rest}) => {
    const app  = useSelector((state) => state.app);
    const auth = useSelector((state) => state.auth);
    const user = useSelector((state) => state.user);

    return (
    <Route
        {...rest}
        render = {props => {
            if(app.isLoading) {
                return <FullScreenLoadingSpinner />;
            }
            else if(!auth.isAuthenticated || !user) {
                return <Redirect to='/login/' />;
            }
            else {
                return <Component {...props} />;
            }
        }}
    />);
};

export default AuthenticatedRoute;
